import React from 'react';
import { Form, Row, Col, Label, Input } from 'reactstrap';
import '../scss/ClientForm.scss';
import intl from 'react-intl-universal';
import ErrorSummary from '../components/common/ErrorSummary';

const ClientForm = ({ errors, input, handleInputChange, disabled }) => {
    return (
        <Form className='my-3'>
            <Row>
                <Col md={12}>
                    <ErrorSummary errors={errors} showEverything={true} ></ErrorSummary>
                </Col>
            </Row>
            <Row form ="true" className='justify-content-around'>
                <Col md={12}>
                    <div className='d-inline-flex col-12 col-md-6 flex-column'>
                        <Label for="clientName" className={`${errors.name ? 'text-danger' : ''}`}>
                            {intl.get('clientForm.name')} {errors.name && (<span className='text-danger'>{errors.name}</span>)}
                        </Label>
                        <Input
                            id="clientName"
                            name="name"
                            placeholder={intl.get('clientForm.placeholder.name')}
                            value={input?.name}
                            onChange={handleInputChange}
                            disabled={disabled}
                        />
                    </div>
                    <div className='d-inline-flex col-12 col-md-6 flex-column ps-2'>
                        <Label for="clientTradeName" className={`${errors.tradeName ? 'text-danger' : ''}`}>
                            {intl.get('clientForm.tradeName')} {errors.tradeName && (<span className='text-danger'>{errors.tradeName}</span>)}
                        </Label>
                        <Input
                            id="clientTradeName"
                            name="tradeName"
                            placeholder={intl.get('clientForm.placeholder.tradeName')}
                            value={input?.tradeName}
                            onChange={handleInputChange}
                            disabled={disabled}
                        />
                    </div>
                </Col>
            </Row>
        </Form>
    )
}

export default ClientForm;