import Api from 'axiosApi/api';
import { CreateTimesheetCommand, UpdateTimesheetCommand, GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse, Timesheet, Project, Task } from 'axiosApi/models';
import { convertDateAsUTC, formatDate, formatDateForAPI, formatUTCDate, toHoursAndMinutes, toMinutes } from 'common/utils';
import { ToastMessageProviderProps } from 'components/ToastMessages/ToastMessageProvider';
import { format, setHours, setMinutes } from 'date-fns';
import intl from 'react-intl-universal';
import { handleAPIError } from '../../common/errorHandler';

export type TimesheetEditProps = {
    date: Date,
    idEmployee?: number | undefined,
    idTimesheet?: number,
    idProject?: number,
    clientProject?: string,
    task?: Task,
    idRow?: string,
    duration?: string,
    description?: string,
}

export type TimesheetInput = {
    idTimesheet?: number | null,
    idEmployee: number,
    idProject?: number | null,
    projectInputValue?: string,
    task?: Task | null,
    description?: string,
    date?: Date,
    duration?: String,
    startTime?: String,
    taskIsModified: Boolean,
    clientProject?: string
}

export const fetchTaskByIdProject = async (api: Api, idProject: number, setTasksByProject: Function, input: TimesheetInput, toast: ToastMessageProviderProps, errors: any, setErrors: Function) => {
    
    if (input.idEmployee && idProject) {
        await api.taskApi.apiVversionTaskGetByEmployeeAndProjectIdEmployeeIdProjectGet(input.idEmployee, idProject, "1", {})
            .then((response) => {
                if (response.data.data) {
                    setTasksByProject(response.data.data.queryResult);
                }
            })
            .catch((error) => {
                handleAPIError(error, toast, errors);
                setErrors({ ...errors });
            });
    } else if (!idProject) {
        setTasksByProject([]);
    }
};

export const fetchTimesheet = async (api: Api, input, setInput: Function, setTasksByProject: Function, toast, errors: any, setErrors: Function) => {
    if (input.idTimesheet) {
        await api.timesheetApi.apiVversionTimesheetIdGet(input.idTimesheet, "1", {}).then((response) => {
            if (response.data.data) {
                setInput({
                    ...input,
                    idEmployee: response.data.data.timesheet.idEmployee,
                    idProject: response.data.data?.timesheet?.task.job.idProject,
                    idTask: response.data.data.timesheet.idTask,
                    description: response.data.data.timesheet.description,
                    date: response.data.data.timesheet.date,
                    duration: toHoursAndMinutes(response.data.data.timesheet.duration),
                    startTime: format(new Date(response.data.data.timesheet.startTime), "hh:mm")
                });
                fetchTaskByIdProject(api, response.data.data?.timesheet?.task.job.idProject, setTasksByProject, input, toast, errors, setErrors);
            }
        })
        .catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({ ...errors });
        });
    }
};

export const handleTimesheetSave = async (
    api: Api,
    input: TimesheetInput | Timesheet,
    closeModal: Function,
    toast,
    errors: any,
    setErrors: Function
): Promise<{ [key: string]: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse } | null> => {

    let _input: TimesheetInput;

    if ("taskIsModified" in input) {
        _input = input as TimesheetInput;
    } else {
        _input = {
            ...(convertTimesheetToTimesheetInput(input as unknown as Timesheet) as TimesheetInput),
            taskIsModified: false
        };
    }

    const startTime = _input.startTime
        ? setMinutes(
            setHours(new Date(_input.date), parseInt(_input.startTime.split(":")[0])),
            parseInt(_input.startTime.split(":")[1])
        )
        : _input.date;

    if (_input.idTimesheet) {
        const cmd: UpdateTimesheetCommand = {
            idEmployee: _input.idEmployee,
            idTask: _input.task?.id,
            description: _input.description,
            date: _input.date,
            duration: toMinutes(_input.duration),
            startTime: startTime,
            id: _input.idTimesheet
        };

        return await api.timesheetApi.apiVversionTimesheetPut("1", cmd, {})
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    if (!response.data.errors) {
                        closeModal();
                        toast.success({ body: intl.get('timesheetComponent.toast.saveConfirmation') });
                        return {
                            [response.data.data.idTask]: {
                                idProject: _input.idProject,
                                timesheetsGroupedByDate: {
                                    [format(new Date(response.data.data.date.toString().replace("Z", "")), "Y-MM-dd")]: {
                                        ...response.data.data,
                                        task: _input.task
                                    }
                                }
                            }
                        };
                    }
                } else {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }
            })
            .catch((error) => {
                handleAPIError(error, toast, errors);
                setErrors({ ...errors });
                return {};
            });

    } else {
        const cmd: CreateTimesheetCommand = {
            idEmployee: _input.idEmployee,
            idTask: _input.task?.id,
            description: _input.description,
            date: convertDateAsUTC(_input.date.toISOString()),
            duration: toMinutes(_input.duration),
            startTime: convertDateAsUTC(startTime.toISOString())
        };

        return await api.timesheetApi.apiVversionTimesheetPost("1", cmd, {})
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    if (!response.data.errors) {
                        closeModal();
                        const responseReturn = {
                            [response.data.data.idTask]: {
                                idProject: _input.idProject,
                                timesheetsGroupedByDate: {
                                    [format(new Date(response.data.data.date.toString().replace("Z", "")), "Y-MM-dd")]: {
                                        ...response.data.data,
                                        task: _input.task
                                    }
                                }
                            }
                        };
                        toast.success({ body: intl.get('timesheetComponent.toast.saveConfirmation') });
                        return responseReturn;
                    }
                } else {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }
            })
            .catch((error) => {
                handleAPIError(error, toast, errors);
                setErrors({ ...errors });
                return null;
            });
    }
};

export const handleTimesheetDelete = async (
    api: Api,
    idTimesheet: number,
    toast,
    errors: any,
    setErrors: Function
): Promise<boolean> => {

    if (idTimesheet != null) {
        return await api.timesheetApi.apiVversionTimesheetIdDelete(idTimesheet, "1", {})
            .then((response) => {
                if (response.status == 200) {
                    toast.success({ body: intl.get('timesheetComponent.toast.deleteconfirmation') });
                    return true;
                } else {
                    return false;
                }
            })
            .catch((error) => {
                handleAPIError(error, toast, errors);
                setErrors({ ...errors });
                return false;
            });
    }
    return false;
};

export const convertTimesheetToTimesheetInput = (timesheet: Timesheet): TimesheetInput => {
    return { idEmployee: timesheet.idEmployee, date: timesheet.date, idTimesheet: timesheet.id, task: timesheet.task, idProject: timesheet.id, duration: toHoursAndMinutes(timesheet.duration), description: timesheet.description, taskIsModified: false }
};

export const convertTimesheetRowsToNewRowsWithTaskId = (timesheetRows: { [key: string]: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse }, selectedDate: Date): { [key: string]: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse } => {
    let response: { [key: string]: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse } = {};
    Object.keys(timesheetRows).forEach((idTask) => {
        response[idTask] = { idProject: timesheetRows[idTask].idProject, timesheetsGroupedByDate: { [formatUTCDate(selectedDate)]: { ...timesheetRows[idTask].timesheetsGroupedByDate[Object.keys(timesheetRows[idTask].timesheetsGroupedByDate)[0]], duration: undefined, description: '', id: null, startTime: selectedDate } } };
    })
    return response;
};

export const getClientProjectName = (projects: Project[], idProject: number): string => {
    if (projects && projects.length > 0) {
        var project = projects.find((x) => x.id == idProject);
        return project ? project.client.name + ' - ' + project.name : '';
    }
    return '';
};

export const getTaskName = (task: Task): string => {
    return task ? task.job.description + ' - ' + task.description : '';
};