import React, { useState, ChangeEventHandler, useEffect, FC } from 'react';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import 'scss/Reports/ReportsComponent.scss';
import './ReportsFilterComponent';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { ReportsFilterComponentProps } from './ReportsFilterComponent';
import { TimeSheetDetailedReport } from '../../axiosApi/models';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { SCOPES } from '../../common/permissions';
import { PermissionsGate } from '../PermissionsGate';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Colors } from 'chart.js';
import { BiSortZA, BiSortAZ } from 'react-icons/bi';
import intl from 'react-intl-universal';
import { TableBody, TableHead } from '@mui/material';
import { handleAPIError } from '../../common/errorHandler';

interface Task {
    idTask: number;
    taskDescription: string;
    time: number;
    idEmployee: number;
    employeeName: string;
    date: Date
}

interface Job {
    idJob: number;
    jobDescription: string;
    tasks: Task[];
    employees: Employee[];
    billableHours: number;
    totalHours: number;
    nonBillableHours: number;
    billingAmount: number;
}

interface Client {
    idClient: number;
    clientName: string;
    projects: Project[];
    totalHours: number;
    billableHours: number;
    nonBillableHours: number;
    billingAmount: number;
}

interface Project {
    idProject: number;
    projectName: string;
    jobs: Job[];
    totalHours: number;
    billableHours: number;
    nonBillableHours: number;
    employees: Employee[];
    billingAmount: number;
}

interface Employee {
    idEmployee: number;
    employeeName: string;
    totalHours: number;
    billableHours: number;
    nonBillableHours: number;
    monthlyRate: number;
    hourlyRate: number;
    billingAmount: number;
}

export type HoursByEmployeeReportComponentProps = {
    filterData:ReportsFilterComponentProps,
    errors: any,
    setErrors: any
}

export const HoursByEmployeeReportComponent: FC<HoursByEmployeeReportComponentProps> = ({filterData, errors, setErrors}) => {

    const api: Api = useApi();
    const toast = useToastMessageQueue();
    const [results, setResults] = React.useState<TimeSheetDetailedReport[]>([]);
    const [employees, setEmployees] = React.useState<Employee[]>([]);
    const [sortValue, setSortValue] = React.useState<string>('Billing Hours');
    const [orderValue, setOrderValue] = React.useState<string>('asc');
    
    ChartJS.register(ArcElement, Tooltip, Legend, Colors);
    
    useEffect(() => {
        const clientJobTaskTree = createClientProjectJobTaskTree(results);
        setEmployees(clientJobTaskTree);
    }, [results]);

    useEffect(() => {
        if(!!filterData && filterData.projects !== undefined){
            fetchData(filterData);
        }
    }, [filterData]);

    const fetchData = async (filterData: ReportsFilterComponentProps) => {
        const response = await api.billingReportsApi.apiVversionBillingDetailedReportAllGet("1", filterData.from, filterData.to, filterData.projects, filterData.clients, filterData.employees, filterData.jobTypes, {}).then((response) => {
        if (response.data.data) {
            setResults(response.data?.data);
        };
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({...errors});
        });
    };

    function createClientProjectJobTaskTree(timesheetData: TimeSheetDetailedReport[]): Employee[] {
        const employees: Employee[] = [];
        if (timesheetData) {
        for (const entry of timesheetData) {
            let employee = employees.find((j) => j.idEmployee === entry.idEmployee);
            if (!employee) {
                employee = {
                    idEmployee: entry.idEmployee,
                    employeeName: entry.employeeName,
                    billableHours: entry.billable ? entry.time : 0,
                    nonBillableHours: entry.billable ? 0 : entry.time,
                    totalHours: entry.time,
                    hourlyRate: entry.hourlyRate,
                    monthlyRate: entry.monthlyRate,
                    billingAmount: !entry.billable ? 0 : (entry.hourlyRate > 0 ? entry.time / 60 * entry.hourlyRate : 0)
                }
                employees.push(employee);
            }
            else {
                employee.billableHours += entry.billable ? entry.time : 0;
                employee.nonBillableHours += entry.billable ? 0 : entry.time;
                employee.totalHours += entry.time;
                employee.billingAmount += !entry.billable ? 0 : (entry.hourlyRate > 0 ? (entry.time * entry.hourlyRate) / 60 : 0);
            }
        }
        }
        return employees;
    };

    function EmployeeRow(props: { employee: Employee, index: number }) {
        const { employee, index } = props;
        return ( <TableRow key={employee.idEmployee} className={(index % 2 == 0) ? "employeeRowOdd" : "employeeRow"} >
            <TableCell component="th" scope="row">
                {employee.employeeName }
            </TableCell>
            <TableCell align="right">${(employee.billingAmount).toFixed(2)}</TableCell>
            <TableCell align="right">
                {(employee.totalHours / 60).toFixed(2)}
            </TableCell>
            <TableCell align="right">
                {(employee.billableHours / 60).toFixed(2)}
            </TableCell>
        </TableRow>);
    };

    const handleSortClick = (sort) => {
        const newOrderValue = sortValue == sort ? (orderValue == 'desc' ? 'asc' : 'desc') : 'asc';
        setOrderValue(newOrderValue);
        setSortValue(sort);
        setEmployees(employees.sort((a,b)=>{
            var sortValue:number;
            switch(sort)
            {
                case "Billing Amount":
                    if(a.billingAmount>b.billingAmount)
                        sortValue = 1;
                    else if(a.billingAmount<b.billingAmount)
                        sortValue = -1;
                    else  sortValue = 0;
                    break;
                case "Total Hours":
                        if(a.totalHours>b.totalHours)
                            sortValue = 1;
                        else if(a.totalHours<b.totalHours)
                            sortValue = -1;
                        else  sortValue = 0;
                        
                        break;
                case "Billing Hours":
                    if(a.billableHours >b.billableHours)
                        sortValue = 1;
                    else if(a.billableHours<b.billableHours)
                        sortValue = -1;
                    else  sortValue = 0;
                    
                    break;
               
            }
            return sortValue * (newOrderValue == 'asc'? 1:-1);
        })); 
    };

    return (
        <PermissionsGate viewScopes={[SCOPES['reports.billing.read']]} editScopes={[SCOPES['reports.billing.read']]} viewRoles={[]} editRoles={[]} RenderError={() => (<span></span>)} >
        {
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell component="th" scope="row" className="billingHeadCell">{intl.get('hoursByEmployeeReportComponent.tableCel.teamMember')}</TableCell>
                        <TableCell component="th" scope="col" className="billingHeadCell" align="right" onClick={() => { handleSortClick('Billing Amount') }} >{intl.get('hoursByEmployeeReportComponent.tableCel.billingAmoun')} {sortValue == 'Billing Amount' ? (orderValue == 'desc' ? <BiSortZA></BiSortZA> : <BiSortAZ></BiSortAZ>) : ''}</TableCell>
                        <TableCell component="th" scope="row" className="billingHeadCell" align="right"  onClick={() => { handleSortClick('Total Hours') }} >{intl.get('hoursByEmployeeReportComponent.tableCel.totalHours')} {sortValue == 'Total Hours' ? (orderValue == 'desc' ? <BiSortZA></BiSortZA> : <BiSortAZ></BiSortAZ>) : ''}</TableCell>
                        <TableCell component="th" scope="row" className="billingHeadCell" align="right"  onClick={() => { handleSortClick('Billing Hours') }} >{intl.get('hoursByEmployeeReportComponent.tableCel.billiableHours')} {sortValue == 'Billing Hours' ? (orderValue == 'desc' ? <BiSortZA></BiSortZA> : <BiSortAZ></BiSortAZ>) : ''}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {
                    employees && employees.map((employee, i) =>
                        <EmployeeRow key={employee.idEmployee} employee={employee} index={i}></EmployeeRow>
                    )
                }
                </TableBody>
            </Table>
        }
        </PermissionsGate>
    )
}




