import { ROLES, SCOPES } from 'common/permissions';
import { PermissionsGate } from 'components/PermissionsGate';
import React from 'react';
import { FaTasks } from "react-icons/fa";
import { HiOutlinePencilAlt, HiOutlineTrash, HiOutlineCheck, HiOutlineEye } from 'react-icons/hi';
import intl from 'react-intl-universal';

const JobRow = ({ idJob, description, billable, jobType, isJiraReady, handleJobEdit, handleTaskJob, handleJobDelete, isJobEditMode, disabled, input, className }) => {

    return (
        <>
            {
                description ?
                <tr className={isJobEditMode(idJob) ? `edit-mode ${className}` : `${className}`}>
                    <td>{description}</td>
                    <td style={{ textAlign: 'center' }}>{billable && <HiOutlineCheck></HiOutlineCheck>} </td>
                    <td>{intl.get(jobType.label)}</td>
                    <td style={{ textAlign: 'center' }}>
                        <PermissionsGate viewScopes={[SCOPES['projects.job.edit']]} editScopes={[SCOPES['projects.job.edit']]} viewRoles={[]} editRoles={[]} >
                            <a title={intl.get('jobRow.editButton.title')} className={disabled === true ? 'mx-2 td-icon disabled' : 'mx-2 td-icon'} role="button" onClick={() => { handleJobEdit(idJob) }}>
                                {
                                    input.id === idJob ?
                                    <HiOutlineEye/> :
                                    <HiOutlinePencilAlt/>
                                }
                            </a>
                        </PermissionsGate>
                        <PermissionsGate viewScopes={[SCOPES['projects.task.add'], SCOPES['projects.task.edit']]} editScopes={[SCOPES['projects.task.add'], SCOPES['projects.task.edit']]} viewRoles={[]} editRoles={[]} >
                            <a title={intl.get('jobRow.taskButton.title')} className={disabled === true ? 'mx-2 td-icon disabled' : 'mx-2 td-icon'} role="button" onClick={() => { handleTaskJob(idJob)}}>
                                <FaTasks/>
                            </a>
                        </PermissionsGate>
                        <PermissionsGate viewScopes={[SCOPES['projects.job.delete']]} editScopes={[SCOPES['projects.job.delete']]} viewRoles={[]} editRoles={[]} >
                            <a title={intl.get('jobRow.deleteButton.title')} className={disabled === true ? 'mx-2 disabled td-icon' : 'mx-2 td-icon'} role="button" onClick={() => { handleJobDelete(idJob) }}>
                                <HiOutlineTrash/>
                            </a>
                        </PermissionsGate>
                    </td>
                </tr> :
                null
            }
        </>
    )
}

export default JobRow;