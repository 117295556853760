import React from 'react';
import { Node, Edge } from '@xyflow/react';
import { getInternalIdById } from './utils';

interface GenerateJSONProps {
  nodes: Node[];
  edges: Edge[];
}

const GenerateJSON: React.FC<GenerateJSONProps> = ({ nodes, edges }) => {
  const getNodeNameById = (id: string) => {
    const node = nodes.find((n) => n.id === id);
    return node ? node.data.label : '';
  };

  const generateJSON = () => {
    const jsonStructure = {
      data: {
        states: nodes.map((node) => ({
          name: node.data.label,
          idWorkflow: 3,
          complete: node.data.final,
          id: node.data.internalId,
          reactFlowId: node.id, 
          initial: node.data.initial ,
          extendedAttributes: {...node.data, position: node.position   },
          isDeleted: false,
        })),
        transitions: edges.map((edge) => ({
          idWorkflowStateFrom: getInternalIdById(nodes, edge.source),
          idWorkflowStateTo: getInternalIdById(nodes, edge.target),
          stateToReactFlowId: edge.target, 
          stateFromReactFlowId: edge.source,
          reactFlowId:edge.id, 
          actionName: edge.data.label || '',
          idScope: 37,
          id: edge.data.internalId,
          isDeleted: false,
          
        })),
        name: "Aprobación TimeOff",
        idModule: 2,
        module: {
          name: "Timeoff",
          id: 2,
          isDeleted: false,
        },
        id: 3,
        isDeleted: false,
      },
      errors: null,
    };

    console.log(JSON.stringify(jsonStructure, null, 2));
  };

  return <button onClick={generateJSON}>Generate JSON</button>;
};

export default GenerateJSON;
