import React, { FC } from 'react';
import Select from 'react-select';
import intl from 'react-intl-universal';

type RequestSelectProps = {
    requestType: any,
    selectedRequest: any,
    handleRequestChange: any
    disabled?:boolean|false
};

const RequestSelect: FC<RequestSelectProps> = ({ requestType, selectedRequest, handleRequestChange, disabled }) => {

  return (
    <>
      <Select
        id='request'
        name='request'
        getOptionLabel={e => intl.get(e.label)}
        getOptionValue={e => e.id}
        placeholder={intl.get('requestSelect.placeholder')}
        value={(requestType != null && selectedRequest != null) ? (requestType.find((req) => req.id === selectedRequest)) : ''}
        onChange={handleRequestChange}
        isLoading={requestType == null}
        options={requestType}
        isClearable={true}
        isDisabled={disabled}
      />
    </>
  );
}

export default RequestSelect;