import React, { useState, useEffect } from 'react';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { CreateNonWorkingDayCommand } from '../../axiosApi/models/create-non-working-day-command';
import { UpdateNonWorkingDayCommand } from '../../axiosApi/models/update-non-working-day-command';
import { HiOutlineSave, HiOutlineTrash, HiOutlineXCircle } from 'react-icons/hi';
import RequiredField from '../RequiredField';
import intl from 'react-intl-universal';
import { Row, Col } from 'reactstrap';
import Spinner from '../Spinner';
import CalendarEventForm from './CalendarEventForm';
import { confirmAlert } from 'react-confirm-alert';
import { handleAPIError } from 'common/errorHandler';

const CalendarEvent = ({ closeModal, eventId, updateEvents }) => {

    const defaultCalendarEventInput = {
        idCountry: null,
        country: {name: ''},
        description: '',
        date: null
    };

    const [input, setInput] = useState(defaultCalendarEventInput);

    const api: Api = useApi();

    const [errors, setErrors] = useState({});

    const [countries, setCountries] = useState(null);

    const [loading, setLoading] = useState<boolean>(false);

    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const toast = useToastMessageQueue();

    const fetchCalendarEvent = async (eventId: number) => {
        setLoading(true);
        const response = await api.nonWorkingDayApi.apiVversionNonWorkingDayIdGet(eventId, "1", {}).then((response) => {
            if (response.data.data) {
                setInput({
                    idCountry: response?.data?.data.idCountry,
                    country: {name: response?.data?.data.country.name},
                    description: response?.data?.data.description,
                    date: new Date(response?.data?.data.date) 
                });
                console.log("Success");
                setLoading(false);
            };
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({ ...errors });
            setLoading(false);
        });
    };

    const fetchCountries = async () => {
        const response = await api.addressApi.apiVversionAddressCountryAllGet("1", {}).then((response) => {
            if (response.data) {
                setCountries(response.data.data);
                console.log("Success");
            };
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({ ...errors });
            setLoading(false);
        });
    };

    useEffect(() => {
        if (eventId) {
            fetchCalendarEvent(eventId);
        };
        fetchCountries();
    }, [eventId]);

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
        setErrors(validate({
            ...input,
            [e.target.name]: e.target.value
        }));
    };

    const handleCountryChange = (e) => {
        const inputVal = {
            ...input,
            idCountry: e.id,
            country: {name: e.name}
        };
        setInput({ ...inputVal });
        setErrors(validate({
            ...input,
            idCountry: e.id
        }));
    };

    const handleDate = (e) => {
        setInput({
            ...input,
            date: e
        });
        setErrors(validate({
            ...input,
            date: e
        }));
    };

    const validate = (input) => {
        let errors: any = {};
        if (!input.idCountry) {
            errors.idCountry = intl.get('validate.errors.isRequired');
        }
        if (!input.description) {
            errors.description = intl.get('validate.errors.isRequired');
        }
        if (!input.date) {
            errors.date = intl.get('validate.errors.isRequired');
        }
        setErrors({ ...errors });
        return Object.keys(errors).length > 0 ? errors : {};
    };

    const handleCancel = () => {
        if (eventId) {
            fetchCalendarEvent(eventId);
        };
        setInput(defaultCalendarEventInput);
        setErrors({});
        closeModal();
    };

    const handleSave = async (e) => {
        setLoadingSave(true);
        e.preventDefault();
        const errors = validate(input);
        if (JSON.stringify(errors) === JSON.stringify({})) {
            if (eventId) {
                let cmd: UpdateNonWorkingDayCommand = {
                    id: eventId,
                    idCountry: input?.idCountry,
                    date: input?.date,
                    description: input?.description
                };
                const response = await api.nonWorkingDayApi.apiVversionNonWorkingDayPut("1", cmd, {}).then((response) => {
                    if (response.data) {
                        toast.success({ body: intl.get('calendarEvent.toast.success.handleSave')});
                        setLoadingSave(false);
                        setInput(defaultCalendarEventInput);
                        closeModal();
                        updateEvents();
                    };
                }).catch((error) => {
                    handleAPIError(error, toast, errors);
                    setErrors({ ...errors });
                    setLoading(false);
                });
            } else {
                let cmd: CreateNonWorkingDayCommand = {
                    idCountry: input?.idCountry,
                    date: input?.date,
                    description: input?.description
                }
                const response = await api.nonWorkingDayApi.apiVversionNonWorkingDayPost("1", cmd, {}).then((response) => {
                    if (response.data) {
                        toast.success({ body: intl.get('calendarEvent.toast.success.handleSave.add')});
                        setLoadingSave(false);
                        setInput(defaultCalendarEventInput);
                        closeModal();
                        updateEvents();
                    }
                }).catch((error) => {
                    handleAPIError(error, toast, errors);
                    setErrors({ ...errors });
                    setLoading(false);
                });
            }
        }
    };

    const handleDelete = (eventId: number) => {
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('delete.modal.calendarEvent.message'),
            buttons: [
                {
                    label: intl.get('delete.modal.cancel.button'),
                    onClick: () => { }
                },
                {
                    label: intl.get('delete.modal.delete.button'),
                    onClick: async () => {
                        setLoadingSave(true);
                        const response = await api.nonWorkingDayApi.apiVversionNonWorkingDayIdDelete(eventId, "1", {}).then((response) => {
                            toast.success({ body: intl.get('calendarEvent.toast.success.handleDelete')});
                            setLoading(false);
                            closeModal();
                            updateEvents();
                        }).catch((error) => {
                            handleAPIError(error, toast, errors);
                            setErrors({ ...errors });
                            setLoading(false);
                        });
                    },
                }
            ]
        });
    };

    return (
        <div className='container'>
            <div className="card-header">
                <h2 className='text-start mb-3'>{eventId ? intl.get('calendarEvent.title.edit') : intl.get('calendarEvent.title.add')}</h2>
            </div>
            <div className='card-body'>
                <div className="row row-address text-start mb-3">
                    {
                        loading === true ?
                        <Spinner small={true} /> :
                        <CalendarEventForm 
                            errors={errors}
                            input={input}
                            handleCountryChange={handleCountryChange}
                            countries={countries}
                            selectedCountry={input.country.name}
                            handleInputChange={handleInputChange}
                            handleDate={handleDate}
                        />
                    }
                </div>
                <Row>
                    <Col md={4} >
                        <RequiredField/>
                    </Col>
                    <Col md={8} className="d-grid gap-2 d-md-flex justify-content-md-end">
                        {
                            loadingSave === true ?
                            <Spinner small={true} /> :
                            <>
                                <button type="button" className="btn btn-outline-dark" onClick={handleCancel}><HiOutlineXCircle className='mb-1'/> {intl.get('cancel.button')}</button>
                                {
                                    eventId &&
                                    <button type="button" className="btn btn-outline-danger" onClick={ () => handleDelete(eventId)}><HiOutlineTrash className='mb-1'/> {intl.get('delete.button')}</button>
                                } 
                                <button type="submit" className="btn btn-primary" onClick={handleSave} disabled={JSON.stringify(errors) !== JSON.stringify({}) || input.country.name === "" ? true : false}><HiOutlineSave className='mb-1'/> {intl.get('save.button')}</button>
                            </>
                        }
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default CalendarEvent;

