import React, { useState, useEffect } from "react";
import TaskList from "./TaskList";
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { CreateTaskCommand, UpdateTaskCommand, Task, JiraIssue } from "axiosApi/models";
import { useToastMessageQueue } from "components/ToastMessages/ToastMessageProvider";
import { Button, Form, Input, Label } from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import JiraIssueSearch from "./JiraIssueSearch";
import { MdOutlineAddCircle } from "react-icons/md";
import { DiJira } from "react-icons/di";
import 'scss/Project/TaskList.scss'; 
import { HiOutlineSave } from "react-icons/hi";
import intl from 'react-intl-universal';
import { handleAPIError } from "common/errorHandler";
import ErrorSummary from "components/common/ErrorSummary";

export type TaskJobInput = {
    description: string,
    idJira: string | null,
    idJob: number,
};

type TaskJobErrors = {
    description?: string,
};

type TaskJobProps = {
    idProject: number,
    idJob: number,
    JobName: string,
    ProjectName: string,
    ClientName: string,
    isJiraReady: boolean,
    closeModal: any
};

const TaskJob = ({ idProject, idJob, JobName, ProjectName, ClientName, isJiraReady, closeModal }: TaskJobProps) => {
    
    const DefaultTaskJobInput = {
        description: '',
        idJob: idJob,
        idJira: ''
    };

    const [input, setInput] = useState<CreateTaskCommand | UpdateTaskCommand | null>(DefaultTaskJobInput);

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const [tasksByJob, setTasksByJob] = useState<Task[] | null>();

    const [errors, setErrors] = useState<TaskJobErrors>({});

    const [showJiraImport, setShowJiraImport] = useState(false);

    const fetchTasksByJob = async (idJob: number) => {
        await api.taskApi.apiVversionTaskGetByJobIdJobGet(idJob, "1", {}).then((response) => {
            if (response.data.data) {
                setTasksByJob(response.data.data.queryResult);
            };
        }).catch((error) => {
            handleAPIError(error, toast, errors)
            setErrors({...errors})
        });
    };

    useEffect(() => {
        if (idJob) {
            fetchTasksByJob(idJob);
        };
    }, [idJob]);

    const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
        const inputVal = {
            ...input,
            [e.target.name]: e.target.value
        };
        setInput({ ...inputVal });
        validate({ ...inputVal });
    };

    const validate = (inputVal) => {
        let errors = {};
        if (!inputVal.description) {
            errors['description'] = intl.get(' validate.errors.isRequired');
        }
        setErrors({ ...errors });
        return Object.keys(errors).length > 0 ? errors : null;
    };

    const doTaskAdd = (input: CreateTaskCommand) => {
        api.taskApi.apiVversionTaskPost("1", input, {}).then((response) => {
            toast.success({ body: intl.get('taskJob.toast.success.doTaskAdd')})
            setInput(DefaultTaskJobInput);
            setTasksByJob((Tasks) => (Tasks) ? [...Tasks, response.data.data] : [response.data.data]);
            response.data.data
        }).catch((error) => {
            handleAPIError(error, toast, errors)
            setErrors({...errors})
        });
    };

    const handleAddTaskJob = async (e) => {
        e.preventDefault();
        const errors = validate(input);
        if (errors == null) {
            doTaskAdd(input);
        }
    };

    const handleSaveTaskJob = async (e) => {
        e.preventDefault();
        const errors = validate(input);
        if (errors == null) {
            await api.taskApi.apiVversionTaskPut("1", input, {}).then((response) => {
                toast.success({ body: intl.get('taskJob.toast.success.handleSaveTaskJob')})
                setInput(DefaultTaskJobInput);
                setTasksByJob((Tasks) => [...Tasks.map(j => { if (j.id != (input as UpdateTaskCommand).id) return j; else return response.data.data; })]);
            }).catch((error) => {
                handleAPIError(error, toast, errors)
                setErrors({...errors})
            });
        }
    };

    const handleTaskEdit = (idTask: number) => {
        if (!isEditMode()) {
            const editingTask = tasksByJob.find((Task: Task) => Task.id == idTask);
            if (editingTask != null)
                setInput({ ...editingTask, id: idTask })
            else
                toast.error({ body: intl.get('taskJob.toast.error.handleTaskEdit')});
        } else {
            setInput(DefaultTaskJobInput);
        }
    };

    const handleTaskDelete = (idTask: number) => {
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('confirm.modal.taskJob.message'),
            buttons: [
                {
                    label: 'Cancel',
                    onClick: () => { }
                },
                {
                    label: 'Delete',
                    onClick: () => {
                        api.taskApi.apiVversionTaskIdDelete(idTask, "1", {}).then((response) => {
                            if (response.status == 200) {

                                toast.success({ body: intl.get('taskJob.toast.success.handleTaskDelete')})
                                setTasksByJob((Tasks) => [...Tasks.filter(j => j.id != idTask)]);
                            }
                            else {
                                toast.error({ header: intl.get('taskJob.toast.error.handleTaskDelete')});
                            }
                        }).catch((error) => {
                            handleAPIError(error, toast, errors)
                            setErrors({...errors})                        
                        });
                    },
                }
            ]
        });
    }

    const isEditMode = () => {
        return Object.keys(input).includes('id');
    };

    const handleCancelEdit = () => {
        setInput(DefaultTaskJobInput);
    };

    const isTaskEditMode = (idTask: number) => {
        return isEditMode() && (input as UpdateTaskCommand).id === idTask;
    };

    const handleJiraImport = () => {
        setShowJiraImport(!showJiraImport);
    };

    const handleIssuesAdd = (issues: JiraIssue[]) => {
        issues.forEach(issue => {
            doTaskAdd({ idJob: idJob, description: issue.key, idJira: issue.idJira });
        })
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h2 className="title">{JobName} - {ProjectName} - {ClientName} {intl.get('taskJob.title')}  {isJiraReady && (showJiraImport ? <MdOutlineAddCircle role="button" type="button" className="m-2 mt-auto" onClick={handleJiraImport}></MdOutlineAddCircle> : <DiJira role="button" type="button" className="m-2 mt-auto" onClick={handleJiraImport}></DiJira>)} </h2>
                    {!showJiraImport && <Form className="">
                        <div className="row">
                            <div className="col-md-12">
                                <ErrorSummary errors={errors} showEverything={true}></ErrorSummary>
                            </div>
                            <div className="col-md-4">
                                <Label check for="description" className={`mx-2 ${errors.description ? 'text-danger' : ''}`}>
                                    {intl.get('taskJob.description')} {errors.description && (<span className='text-danger'>{errors.description}</span>)}
                                </Label>
                                <Input
                                    autoFocus={true}
                                    type="text"
                                    className="form-control my-2"
                                    id="description"
                                    name="description"
                                    placeholder={intl.get('taskJob.placeholder.description')}
                                    onChange={handleInputChange}
                                    value={input.description}
                                ></Input>

                            </div>
                            <div className="col-md-3">
                                <Label check for="idJira" className="mx-2">
                                    {intl.get('taskJob.jira')}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control my-2"
                                    id="idJira"
                                    name="idJira"
                                    placeholder={intl.get('taskJob.placeholder.jira')}
                                    onChange={handleInputChange}
                                    value={input.idJira}
                                ></Input>
                            </div>
                            <div className="col-md-2">
                                {
                                    !isEditMode() &&
                                    <div className="d-flex justify-content-start align-items-center h-100">
                                        <Button outline type="submit" className="btn btn-primary m-2 mt-auto" onClick={handleAddTaskJob}>{intl.get('projectDetail.add.button')}</Button>
                                    </div>
                                }
                                {
                                    isEditMode() &&
                                    <div className="d-flex justify-content-start align-items-cente h-100">
                                        <Button outline type="button" className="btn btn-outline-dark m-2 mt-auto" onClick={handleCancelEdit}>{intl.get('projectDetail.cancel.button')}</Button>
                                        <Button outline type="submit" className="btn btn-primary m-2 mt-auto" onClick={handleSaveTaskJob}><HiOutlineSave className='mb-1'/> {intl.get('projectDetail.save.button')}</Button>
                                    </div>
                                }
                            </div>
                        </div>
                    </Form>
                    }
                </div>
            </div>
            {showJiraImport &&
                <div className='row m-0'>
                    <JiraIssueSearch idProject={idProject} handleIssuesAdd={handleIssuesAdd} tasks={tasksByJob} />
                </div>
            }
            <div className='row m-0 tasklist-modal'>
                <TaskList tasksByJob={tasksByJob} handleTaskEdit={handleTaskEdit} handleTaskDelete={handleTaskDelete} isTaskEditMode={isTaskEditMode} />
            </div>
            <div className="d-grid d-md-flex justify-content-md-end mt-3 me-3">
                <button type="button" className="btn btn-outline-dark" onClick={closeModal} >{intl.get('close.button')}</button>
            </div>
        </>
    );
}

export default TaskJob;