import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import ClientPagination from './ClientPagination';
import ClientsList from './ClientsList';
import Filters from '../fixture/filtersClient';
import Spinner from './Spinner';
import '../scss/Clients.scss';
import { useApi } from '../api/ApiProvider';
import Api from '../axiosApi/api';
import {PermissionsGate } from '../components/PermissionsGate';
import { SCOPES, ROLES } from "../common/permissions"; 
import { Client } from 'axiosApi/models';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import intl from 'react-intl-universal';
import ItemsSelect from './ItemsSelect';
import { handleAPIError } from '../common/errorHandler';

export type ClientsInput = {
	queryTerm?: string | null,
	sortValue?: string | null,
	orderValue?: string | null,
	filterValue?: null,
	currentPage?: number,
	clicked?: boolean
};

export type SearchResult = {
	totalRecords: number | null,
	clients: Client[]
};

const Clients = () => {

	const [input, setInput] = useState<ClientsInput | null>({
		queryTerm: "",
		sortValue: "Name",
		orderValue: "asc",
		filterValue: null,
		currentPage: 1,
		clicked: false,
	});

	const api: Api = useApi();

	const [searchResult, setSearchResult] = useState<SearchResult | null>({
		totalRecords: null,
		clients: []
	});

	const [loading, setLoading] = useState<boolean>(false);

	const [pageCount, setpageCount] = useState<number>(0);

	const [limit, setLimit] = useState<number>(() => {
		return parseInt(localStorage.getItem('itemsPerPageClients'), 10) || 10;
	});

	const [errors, setErrors] = useState({});

	useEffect(() => {
		setLimit(parseInt(localStorage.getItem('itemsPerPageClients'), 10) || 10);
	}, []);

	const mapFilter = new Map();

	// mapFilter = {
	// 	"status": {"parentRef": parentRef, "childrenRef": mapChildren},
	// 	"category": {"parentRef": parentRef, "childrenRef": mapChildren}
	// }

	for (let i = 0; i < Filters.filters.length; i++) {
		const parentRef = React.createRef();
		const mapChildren = new Map();
		for (let j = 0; j < Filters.filters[i].filtersItems.length; j++) {
			const childrenRef = React.createRef();
			mapChildren.set(Filters.filters[i].filtersItems[j].value, childrenRef);
		}
		let valor = { parentRef: parentRef, childrenRef: mapChildren };
		mapFilter.set(Filters.filters[i].value, valor);
	};

	const toast = useToastMessageQueue();

	const fetchClients = async (term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>) => {
		setLoading(true);
		const response = await api.clientApi.apiVversionClientSearchGet("1", input.queryTerm, input.currentPage, limit, input.sortValue, input.orderValue, f, {}).then((response) => {
			if (response.data.data) {
				setSearchResult({
					totalRecords: response.data?.data?.totalRecords,
					clients: response.data?.data?.queryResult
				});
			} else {
				setSearchResult({
					totalRecords: null,
					clients: null
				});
			};
			setLoading(false);
			console.log("Success");
		}).catch((error) => {
			handleAPIError(error, toast, errors);
			setErrors({...errors});
		});
	};

	useEffect(() => {
		fetchClients();
	}, [input, limit]);

	const onSearch = (queryString) => {
		setInput({ ...input, queryTerm: queryString, currentPage: 1 });
	};

	const handlePageClick = pageNum => setInput({ ...input, currentPage: pageNum });

	const nextPage = () => setInput({ ...input, currentPage: input.currentPage + 1 });

	const prevPage = () => setInput({ ...input, currentPage: input.currentPage - 1 });

	const initialPage = () => setInput({ ...input, currentPage: 1 });

	const finalPage = () => setInput({ ...input, currentPage: pageCount });

	const handleFilter = async (e) => {
		let checked = e.target.checked;
		let value = e.target.value;
		let parentValue = e.target.getAttribute("parent");
		let newFilterValue = null;
		if (parentValue === null) {
			let childrenMap = mapFilter.get(value).childrenRef;
			let values: any = Array.from(childrenMap.values());
			if (input.filterValue === null) {
				newFilterValue = { filters: [] };
			} else {
				newFilterValue = input.filterValue;
			}
			if (checked) {
				let index = newFilterValue.filters.findIndex(filter => filter.name === value);
				let keys = Array.from(childrenMap.keys());
				if (index >= 0) {
					let filtersUnselected = [];
					for (let i = 0; i < keys.length; i++) {
						let filterKey = newFilterValue.filters[index].selected.includes(keys[i]);
						if (!filterKey) {
							filtersUnselected.push(keys[i]);
						}
					}
					for (let i = 0; i < filtersUnselected.length; i++) {
						childrenMap.get(filtersUnselected[i]).current.checked = true;
						newFilterValue.filters[index].selected.push(filtersUnselected[i]);
					}
				} else {
					keys.forEach(key => {
						childrenMap.get(key).current.checked = true;
					})
					newFilterValue.filters.push({ name: value, selected: keys });
				}
			} else {
				newFilterValue = input.filterValue;
				values.forEach(element => {
					element.current.checked = false;
				});
				let indexFilter = newFilterValue.filters.findIndex(filter => filter.name === value);
				newFilterValue.filters[indexFilter].selected = [];
			}
			setInput({ ...input, filterValue: newFilterValue, currentPage: 1 });
		} else {
			if (input.filterValue === null) {
				newFilterValue = { filters: [] };
			} else {
				newFilterValue = input.filterValue;
			}
			if (checked) {
				let index = newFilterValue.filters.findIndex(filter => filter.name === parentValue);
				if (index >= 0) {
					newFilterValue.filters[index].selected.push(value);
					let filterItem = Filters.filters.find(filter => filter.value === parentValue);
					if (newFilterValue.filters[index].selected.length === filterItem.filtersItems.length) {
						let parentRefValue = mapFilter.get(parentValue).parentRef;
						parentRefValue.current.checked = true;
					}
				} else {
					newFilterValue.filters.push({ name: parentValue, selected: [value] });
				}
			} else {
				let parentRefValue = mapFilter.get(parentValue).parentRef;
				parentRefValue.current.checked = false;
				newFilterValue = input.filterValue;
				let indexParent = newFilterValue.filters.findIndex(filter => filter.name === parentValue);
				let indexValue = newFilterValue.filters[indexParent].selected.findIndex(filter => filter === value);
				let selected = newFilterValue.filters[indexParent].selected;
				selected.splice(indexValue, 1);
				newFilterValue.filters[indexParent].selected = selected;
			}
			setInput({ ...input, filterValue: newFilterValue, currentPage: 1 });
		}
	};

	const handleClickButtonFilter = () => {
		setInput({ ...input, clicked: !input.clicked, currentPage: 1 });
	};

	return (
		<div className="container">
			<div className='card mt-4'>
				<div className="card-header">
					<h2 className="title">{intl.get('clients.header')}</h2>
				</div>
				<div className='card-body'>
                    <PermissionsGate viewScopes={[SCOPES['clients.read']]} editScopes={[SCOPES['clients.edit']]} viewRoles={[ROLES.clientReader]} editRoles={[ROLES.clientWriter]} RenderError={()=>{return <span>{intl.get('permissionsGate')}</span>}}>
						<div className='row'>
							<div className='' id={`${input.clicked ? 'column-2' : ''}`} >
								<Navbar onSearch={onSearch} limit={limit} setLimit={setLimit}/>
								{
									loading === true ?
									<Spinner /> :
									<>
										<ClientsList searchResult={searchResult} input={input} setInput={setInput} />
										<div className="pagination row w-100">
											<div className="col-10 col-xl-11 d-flex justify-content-center">
												<ClientPagination 
													handlePageClick={handlePageClick} 
													limit={limit} searchResult={searchResult} 
													nextPage={nextPage} prevPage={prevPage} 
													input={input} pageCount={pageCount} 
													setpageCount={setpageCount} 
													initialPage={initialPage} 
													finalPage={finalPage} 
												/>
											</div>
											<div className="col-2 col-xl-1 d-flex justify-content-end">
												<ItemsSelect
													input={input}
													setInput={setInput}
													limit={limit} 
													setLimit={setLimit} 
													storageKey="itemsPerPageClients"
												/>
											</div>
										</div>
									</>
								}
							</div>
						</div>
                	</PermissionsGate>
				</div>
			</div>
		</div>
	)
}

export default Clients;