import React from 'react';
import { Form, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import CountrySelect from './CountrySelect';
import StateSelect from './StateSelect';
import AddressTypeSelect from './AddressTypeSelect';
import '../scss/AddressForm.scss';
import intl from 'react-intl-universal';
import ErrorSummary from 'components/common/ErrorSummary';

const AddressForm = ({ errors, input, handleInputChange, selectedCountry, selectedState, countries, states, handleCountryChange, handleStateChange, addressTypes, selectedAddressType, handleAddressTypeChange }) => {
    
    return (
        <Form>
            <FormGroup>
                <Row>
                    <Col md={12}>
                        <ErrorSummary errors={errors} showEverything={true} ></ErrorSummary>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Label for="Address1" className={`${errors.address1 ? 'text-danger' : ''}`}>
                            {intl.get('addressForm.address1')} {errors.address1 && (<span className='text-danger'>{errors.address1}</span>)}
                        </Label>
                        <Input
                            type="text"
                            id="Address1"
                            name="address1"
                            placeholder={intl.get('addressForm.placeholder.address1')}
                            value={input.address1}
                            onChange={handleInputChange}
                        />
                    </Col>
                    <Col md={6}>
                        <Label for="Address2">{intl.get('addressForm.address2')}</Label>
                        <Input
                            type="text"
                            id="Address2"
                            name="address2"
                            placeholder={intl.get('addressForm.placeholder.address2')}
                            value={input.address2}
                            onChange={handleInputChange}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col md={6}>
                        <Label for="Address3">{intl.get('addressForm.address3')}</Label>
                        <Input
                            type="text"
                            id="Address3"
                            name="address3"
                            placeholder={intl.get('addressForm.placeholder.address3')}
                            value={input.address3}
                            onChange={handleInputChange}
                        />
                    </Col>
                    <Col md={6}>
                        <Label for="addressType" className={`${errors.idAddressType ? 'text-danger' : ''}`}>
                            {intl.get('addressForm.addressType')} {errors.idAddressType && (<span className='text-danger'>{errors.idAddressType}</span>)}
                        </Label>
                        <AddressTypeSelect
                            handleAddressTypeChange={handleAddressTypeChange}
                            selectedAddressType={selectedAddressType}
                            addressTypes={addressTypes}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col md={6}>
                        <Label for="addressCountry" className={`${errors.idCountry ? 'text-danger' : ''}`}>
                            {intl.get('addressForm.country')} {errors.idCountry && (<span className='text-danger'>{errors.idCountry}</span>)}
                        </Label>
                        <CountrySelect
                            handleCountryChange={handleCountryChange}
                            selectedCountry={selectedCountry}
                            countries={countries}
                            inputCountry={input.country.name}
                        />
                    </Col>
                    <Col md={6}>
                        <Label for="addressState" className={`${errors.idStateProvince ? 'text-danger' : ''}`}>
                            {intl.get('addressForm.stateProvince')} {errors.idStateProvince && (<span className='text-danger'>{errors.idStateProvince}</span>)}
                        </Label>
                        <StateSelect
                            handleStateChange={handleStateChange}
                            selectedState={selectedState}
                            states={states}
                            inputState={input.stateProvince.name}
                            inputCountry={input.country.name}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col md={6}>
                        <Label for="addressCity" className={`${errors.city ? 'text-danger' : ''}`}>
                            {intl.get('addressForm.city')} {errors.city && (<span className='text-danger'>{errors.city}</span>)}
                        </Label>
                        <Input
                            type="text"
                            id="addressCity"
                            name="city"
                            value={input.city}
                            onChange={handleInputChange}
                            placeholder={intl.get('addressForm.placeholder.city')}
                        />
                    </Col>
                    <Col md={6}>
                        <Label for="addressZipCode" className={`${errors.zipCode ? 'text-danger' : ''}`}>
                            {intl.get('addressForm.zipCode')} {errors.zipCode && (<span className='text-danger'>{errors.zipCode}</span>)}
                        </Label>
                        <Input
                            type="text"
                            maxLength={10}
                            id="addressZipCode"
                            name="zipCode"
                            value={input.zipCode || ''}
                            onChange={handleInputChange}
                            placeholder={intl.get('addressForm.placeholder.zipCode')}
                        />
                    </Col>
                </Row>
            </FormGroup>
        </Form>
    )
}

export default AddressForm;