import React, {useState} from 'react';
import ModalContactInfo from './ModalContactInfo';
import '../scss/ContactInfoRow.scss';
import {useApi} from '../api/ApiProvider';
import Api from '../axiosApi/api';
import { confirmAlert } from 'react-confirm-alert';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { PermissionsGate}  from './PermissionsGate';
import { HiOutlinePencilAlt, HiOutlineTrash } from 'react-icons/hi';
import intl from 'react-intl-universal';
import { handleAPIError } from '../common/errorHandler';

const ContactInfoRow = ({ contactInfoId, contactName, emailAddress, phone1, phone2, role, editScopes, deleteScopes, disabled, contactInfos, setContactInfos, className, errors, setErrors }) => {

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const [isContactInfoRowDetailOpen, setIsContactInfoRowDetailOpen] = useState(false);

    const closeContactInfoRowDetail = () => setIsContactInfoRowDetailOpen(false);

    const handleContactInfoRowDetailOpen = () => {
        setIsContactInfoRowDetailOpen(true);
    };

    const handleDelete = () => {
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('delete.modal.contactInfoRow.message'),
            buttons: [
                {
                    label: intl.get('delete.modal.cancel.button'),
                    onClick: () => { }
                },
                {
                    label: intl.get('delete.modal.delete.button'),
                    onClick: async () => {
                        const response = await api.contactInfoApi.apiVversionContactInfoContactinfoIdDelete(contactInfoId, "1", {}).then((response) => {
                            console.log(response.data);
                            let newContactInfos = contactInfos.filter(contact => contact.id != contactInfoId);
                            setContactInfos(newContactInfos);
                            toast.success({ body: intl.get('contactInfoRow.toast.success.handleDelete')});
                        }).catch((error) => {
                            handleAPIError(error, toast, errors);
                            setErrors({...errors});
                        });
                    },
                }
            ]
        });
    };

    return (
        <>
            {
                contactName ?
                <tr className={className}>
                    <td>{contactName}</td>
                    <td>{emailAddress}</td>
                    <td>{phone1}</td>
                    <td>{phone2}</td>
                    <td>{role}</td>
                    <td className='text-center'>
                        <PermissionsGate viewScopes={editScopes} editScopes={editScopes} viewRoles={[]} editRoles={[]} >  
                            <a className={ disabled === true ? 'td-icon disabled mx-1' : 'td-icon mx-1'} onClick={handleContactInfoRowDetailOpen} title={intl.get('contactInfoRow.editButton.title')}>
                                <HiOutlinePencilAlt/>
                            </a>
                            <ModalContactInfo isOpen={isContactInfoRowDetailOpen} closeModal={closeContactInfoRowDetail} contactInfoId={contactInfoId} contactInfos={contactInfos} setContactInfos={setContactInfos} />
                        </PermissionsGate>
                        <PermissionsGate viewScopes={deleteScopes} editScopes={deleteScopes} viewRoles={[]} editRoles={[]} > 
                            <a className={ disabled === true ? 'td-icon disabled mx-1' : 'td-icon mx-1'} onClick={handleDelete} title={intl.get('contactInfoRow.deleteButton.title')}> 
                                <HiOutlineTrash/>
                            </a>    
                        </PermissionsGate>
                    </td>
                </tr> :
                null
            }
        </>
    )   
}

export default ContactInfoRow;