import React from 'react';
import { useNavigate } from 'react-router-dom';

const WorkflowRow = ({ id, name, className }) => {

    const navigate = useNavigate();

    const handleClickRow = () => {
        navigate('/workflow/' + id);
    };

    return (
        <>
            {
                name ?
                <tr onClick={handleClickRow} className={className}>
                    <td>{name}</td>
                </tr> :
                null
            }
        </>
    )   
}

export default WorkflowRow;