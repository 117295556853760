import { ROLES, SCOPES } from 'common/permissions';
import { PermissionsGate } from 'components/PermissionsGate';
import React,{ useEffect } from 'react';
import { HiOutlinePencilAlt, HiOutlineTrash, HiOutlineEye } from 'react-icons/hi';
import intl from 'react-intl-universal';

const ProjectEmployeeRow = ({name, lastName, hourlyRate, monthlyRate, role, dueDate, handleEmployeeEdit, idEmployee, timeSheetViewer, handleEmployeeDelete, isEmployeeEditMode, disabled, input, className, startDate, allocation, allocationType }) => {
    
    return (
        <>
            {
                name ?
                <tr className={isEmployeeEditMode(idEmployee) ? `edit-mode ${className}` : ` ${className}`}>
                    <td>{name} {lastName}</td>
                    <td className='text-center'>{hourlyRate}</td>
                    <td className='text-center'>{monthlyRate}</td>
                    <td className='text-center'>{allocation}{allocationType==1?' %': ' hs.'}</td>
                    <td>{role}</td>
                    <td className='text-center'><input type='checkbox' id="timeSheetViewer" name='timeSheetViewer' checked={timeSheetViewer} disabled={true} /></td>
                    <td className='text-center'>{startDate} - {dueDate}</td>
                    <td className='text-center'>
                        <PermissionsGate viewScopes={[SCOPES['projects.employees.edit']]} editScopes={[SCOPES['projects.employees.edit']]} viewRoles={[]} editRoles={[]} >
                            <a title={intl.get('employeeRow.editButton.title')} className={disabled === true ? 'td-icon disabled mx-1' : 'td-icon mx-1'} role="button" onClick={() => { handleEmployeeEdit(idEmployee) }}>
                                {
                                    input.id === idEmployee ?
                                    <HiOutlineEye/> :
                                    <HiOutlinePencilAlt/>
                                }
                            </a>
                        </PermissionsGate>
                        <PermissionsGate viewScopes={[SCOPES['projects.employees.delete']]} editScopes={[SCOPES['projects.employees.delete']]} viewRoles={[]} editRoles={[]} >
                            <a title={intl.get('employeeRow.deleteButton.title')} className={disabled === true ? 'td-icon disabled mx-1' : 'td-icon mx-1'} role="button" onClick={() => { handleEmployeeDelete(idEmployee) }}>
                                <HiOutlineTrash/>
                            </a>
                        </PermissionsGate>
                    </td>
                </tr> :
                null
            }
        </>
    )
}

export default ProjectEmployeeRow;