import React, { FC } from 'react';
import Select from 'react-select';
import { Role } from '../../axiosApi/models';
import intl from 'react-intl-universal';
import { sortCollection } from '../../common/utils';
import { SORT_TYPES } from '../../common/constants';

type RoleSelectProps = {
  handleRoleChange: any,
  selectedRole: any,
  roles: any,
  disabled: any
};

const RoleSelect: FC<RoleSelectProps> = ({ handleRoleChange, roles, selectedRole, disabled }) => {

  const rolesSortedByName = sortCollection(roles, 'label', SORT_TYPES.ASC);

  return (
    <Select
      id='idRole'
      name='idRole'
      getOptionLabel={e => intl.get(e.label)}
      getOptionValue={e => e.id}
      placeholder={intl.get('employeeProjectForm.placeholder.role')}
      value={disabled ? '' : (roles != null && selectedRole != null) ? (roles.find((role: Role) => role.id === selectedRole)) : ''}
      onChange={handleRoleChange}
      isLoading={roles == null}
      options={rolesSortedByName}
      isDisabled={disabled}
    />
  );
}

export default RoleSelect;