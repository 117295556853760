import React, { FC, useState, useEffect } from 'react';
import { format, addDays } from 'date-fns';
import TimesheetRow from './TimesheetRow';
import { GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse, Project, Task } from 'axiosApi/models';
import { useApi } from '../../api/ApiProvider';
import Api from 'axiosApi/api';
import { formatDateForAPI, toHoursAndMinutes } from 'common/utils';
import intl from 'react-intl-universal';
import { TimesheetEditProps } from './timesheetUtils';
import { handleAPIError } from '../../common/errorHandler';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';

type TimesheetTableProps = {
    api: Api
    idEmployee: number,
    date: Date,
    projects: Project[] | undefined,
    timesheetRows: { [key: string]: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse } | undefined
    timesheetRowsOrder: string[] | undefined
    durationClick: (timesheetEditProps: TimesheetEditProps) => void,
    workingWeekDays: number,
    handleTimesheetSave: Function,
    newRows: Array<string>,
    deleteRow: Function,
    loading: boolean,
    setLoading: Function,
    dateChanged: boolean,
    setDateChanged: Function,
};

const TimesheetTable: FC<TimesheetTableProps> = ({ api, date, projects, timesheetRows, timesheetRowsOrder, durationClick, workingWeekDays, idEmployee, handleTimesheetSave, newRows, deleteRow, loading, setLoading, dateChanged, setDateChanged }) => {
    const [nonWorkingDayDates, setNonWorkingDayDates] = useState([]);
    const apiE: Api = useApi();
    const [errors, setErrors] = useState({});
    const toast = useToastMessageQueue();


    const fetchEvents = async () => {
        setLoading(true);
        const start = new Date("1970-01-01T00:00:00");
        const end = new Date("2070-01-01T00:00:00");
        const response = await apiE.eventApi.apiVversionEventAllGet("1", "", start, end, {}).then((response) => {
            const renamedEvents = response?.data?.data?.queryResult?.map(event => ({
                ...event,
                start: new Date(event.from),
                end: new Date(event.to),
                from: undefined,
                to: undefined
            }));

            const nonWorkingDayEvents = renamedEvents.filter(event => event.eventType === "NonWorkingDay");
            const nonWorkingDayDates = nonWorkingDayEvents.map(event => ({
                date: event.start
            }));
            setNonWorkingDayDates(nonWorkingDayDates);
            setLoading(false);
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({...errors});
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchEvents();
    }, [location.pathname]);

    const totalDurationByDate = (formatedDate: string): string => {
        return toHoursAndMinutes(timesheetRows ? ((Object.keys(timesheetRows).map((idTask) => { return Object.keys(timesheetRows[idTask].timesheetsGroupedByDate).includes(formatedDate) ? (timesheetRows[idTask].timesheetsGroupedByDate[formatedDate].duration ? timesheetRows[idTask].timesheetsGroupedByDate[formatedDate].duration : 0) : 0 })).reduce((subtotal, current) => subtotal + current, 0)) : 0);
    };

    const totalWeek = (): string => {
        return toHoursAndMinutes(timesheetRows ? ((Object.keys(timesheetRows).map((idTask) => { return Object.keys(timesheetRows[idTask].timesheetsGroupedByDate).map((date) => { return timesheetRows[idTask].timesheetsGroupedByDate[date].duration ?? 0 }).reduce((subtotal, current) => subtotal + current, 0) })).reduce((subtotal, current) => subtotal + current, 0)) : 0);
    };

    const isTaskInTimesheetRows = (idTask: number) => {
        return Object.keys(timesheetRows ?? []).includes(idTask.toString());
    };

    const getTaskFromRow = (timesheetRow: GetTimesheetsByIdEmployeeAndDateRangeGroupedByDateResponse): Task | null => {
        return (timesheetRow && timesheetRow.timesheetsGroupedByDate && Object.keys(timesheetRow.timesheetsGroupedByDate).length > 0) ? timesheetRow.timesheetsGroupedByDate[Object.keys(timesheetRow.timesheetsGroupedByDate)[0]].task : null;
    }


    return (
        <>
            <div className="d-flex pt-2 pr-2">
                <div className="timesheet-first-column-width row flex-grow-1">
                    <div className="col-8 pr-2"></div>
                    <div className="col-4 pl-0"></div>
                </div>
                {[...Array(workingWeekDays)].map((x, i) => <div className="timesheet-column-width text-end" key={'ts_h_' + i}> <small className="font-weight-400 text-gray">{format(addDays(date, i), "E, dd LLL")} </small> </div>)}
                <div className="timesheet-delete-column-width text-end text-dark-light h5"></div>
                <div className="timesheet-totals-column-width text-end text-dark-light h5"> {intl.get('timesheetTable.total')} </div>

            </div>
            <div className="d-flex pr-2 align-items-center border-bottom">
                <div className="timesheet-first-column-width row flex-grow-1">
                    <div className="col-8 pr-2"> <span className="h5 text-dark-light"> {intl.get('timesheetTable.clientProject')}</span> </div>
                    <div className="col-4 pl-0"> <span className="h5 text-dark-light"> {intl.get('timesheetTable.task')}</span> </div>
                </div>
                {[...Array(workingWeekDays)].map((x, i) => {
                    let isNonWorkingDay = false;
                    nonWorkingDayDates.forEach(nonWorkingDate => {
                        const selectedDate = new Date(date);
                        selectedDate.setDate(selectedDate.getDate() + i);
                        if (nonWorkingDate?.date?.getDate() === selectedDate?.getDate() && nonWorkingDate?.date?.getMonth() === selectedDate?.getMonth()) {
                            isNonWorkingDay = true;
                        }
                    });
                    const isWeekend = i >= 5 ? true : false;
                    const color = isNonWorkingDay ? "#ffc107" : "";
                    return (
                        <div className="timesheet-column-width d-flex justify-content-end align-items-center" key={'ts_h_sum' + i}>
                            <span className="h5 text-dark-light font-weight-600" style={{ color: isWeekend ? "#6ea8fe" : color }}>
                                {totalDurationByDate(formatDateForAPI(addDays(date, i)))}</span></div>
                    );
                }
                )}
                <div className="timesheet-delete-column-width text-end text-dark-light h5"></div>
                <div className="timesheet-totals-column-width text-end text-dark-light h5"><span className="h5 text-dark-light font-weight-600">{totalWeek()}</span>
                </div>
            </div>
            {timesheetRows && timesheetRowsOrder.map((timesheetRowKey, i) => <TimesheetRow api={api} key={timesheetRowKey} prefixKey={i.toString()} firstDay={date} workingWeekDays={workingWeekDays} durationClick={durationClick} projects={projects} timesheetRow={timesheetRows[timesheetRowKey]} preselectedTask={getTaskFromRow(timesheetRows[timesheetRowKey])} idEmployee={idEmployee} handleTimesheetSave={handleTimesheetSave} deleteRow={deleteRow} isTaskInTimesheetRows={isTaskInTimesheetRows} loading={loading} dateChanged={dateChanged} setDateChanged={setDateChanged}></TimesheetRow>)}
            {newRows.map((obj, i) => <TimesheetRow api={api} key={obj} prefixKey={obj} firstDay={date} workingWeekDays={workingWeekDays} durationClick={durationClick} projects={projects} timesheetRow={null} preselectedTask={null} idEmployee={idEmployee} handleTimesheetSave={handleTimesheetSave} deleteRow={deleteRow} isTaskInTimesheetRows={isTaskInTimesheetRows} loading={loading} dateChanged={dateChanged} setDateChanged={setDateChanged}></TimesheetRow>)}
        </>
    );
}

export default TimesheetTable;

