import React, { useState } from 'react';
import { Row, Col, Label } from 'reactstrap';
import RequestSelect from './RequestSelect';
import DateRangePickerDialog from 'components/DateRangePickerDialog';
import intl from 'react-intl-universal';
import '../../scss/Time Off/TimeOffRequest.scss';
import StyledDropzone from "./TimeOffFileUploader";
import WorkflowActionsBar from 'components/Workflow/WorkflowActionsBar';
import EmployeeSelect from 'components/Project/EmployeeSelect';
import { PermissionsGate } from '../PermissionsGate';
import { SCOPES } from 'common/permissions';
import { ENTITY_TYPE_EMPLOYEE } from 'common/constants';
import { IEditingModeSetings } from 'components/Workflow/WorkflowActionsBar';
import { isEditableByOwner } from '../hasPermission';
import Chip from '@mui/material/Chip';
import { HiOutlineDownload } from 'react-icons/hi';
import ErrorSummary from 'components/common/ErrorSummary';

const TimeOffRequestForm = ({ requestType, input, setInput, errors, loading, handleRequestChange, handleDaySelect, handleSave, employees, handleEmployeeChange, HRView, setComments=null }) => {
    const [wfEditSettings, setWfEditSettings] = useState<IEditingModeSetings>();

    const handleEditingMode = (params: IEditingModeSetings) => {
        setWfEditSettings(params);
    }

    const getStateColor = (): any => {
        return input.workflowStateName === "Pending" || input.workflowStateName === "Pendiente" ? "warning" : input.workflowStateName === "Approved" || input.workflowStateName === "Aprobado" ? "success" : input.workflowStateName === "Observed" || input.workflowStateName === "Observado" ? "default" : input.workflowStateName === "Open" || input.workflowStateName === "Abierto" ? "primary" : input.workflowStateName === "Reopen" || input.workflowStateName === "Reabrir" ? "secondary" : "error";
    }
    return (
        <div className='container'>
            <Row className='justify-content-around'>
                <Col>
                    <ErrorSummary errors={errors} showEverything={true} ></ErrorSummary>
                </Col>
            </Row>
            <Row className='justify-content-around'>

                
                <Col md={input.id > 0 ? 6 : 12}>
                    <div>
                        <Label for="TimeOffRequestType" className={`${errors.idTimeOffRequestType ? 'text-danger' : ''}`}>
                            {intl.get('timeOff.type')}
                        </Label>
                        <RequestSelect requestType={requestType} selectedRequest={input.idTimeOffRequestType} handleRequestChange={handleRequestChange} disabled={!(wfEditSettings?.edit || isEditableByOwner(ENTITY_TYPE_EMPLOYEE, input.idEmployee, wfEditSettings))} />
                    </div>
                </Col>
                {input.id > 0 &&
                    <Col md={6}>
                        <div className='justify-content-end'>
                            <Label for="status" className='d-block'>{intl.get('timeOffApprove.status.label')}</Label>
                            <Chip label={input.workflowStateName} color={getStateColor()} className={getStateColor() === "default" ? 'observed' : ''} />
                        </div>
                    </Col>
                }
            </Row>
            <Row>
                <Col md={12}>
                    <div className='mt-20'>
                        <Label for="TimeOffRequestType" className={`${errors.range ? 'text-danger' : ''}`}>
                            {intl.get('timeOff.time.range')}
                        </Label>
                        <DateRangePickerDialog
                            disabled={!(wfEditSettings?.edit || isEditableByOwner(ENTITY_TYPE_EMPLOYEE, input.idEmployee, wfEditSettings))}
                            inputName="dateRange"
                            className='timesheet-datepicker'
                            selected={input.range}
                            setRange={handleDaySelect}
                            required
                        />
                    </div>
                </Col>
            </Row>
            {
                HRView ?
                    <PermissionsGate viewScopes={[SCOPES['timeoff.rrhh']]} editScopes={[SCOPES['timeoff.rrhh']]} entityType={ENTITY_TYPE_EMPLOYEE} viewRoles={[]} editRoles={[]} >
                        <Row>
                            <Col md={12}>
                                <div className='mt-20'>
                                    <Label check for="idEmployee" className="form-label">
                                        {intl.get('timeOffRequestForm.employeeName')}
                                    </Label>
                                    <EmployeeSelect
                                        handleEmployeeChange={handleEmployeeChange}
                                        employees={employees}
                                        selectedEmployee={input.idEmployee}
                                        disabled={!(wfEditSettings?.edit || isEditableByOwner(ENTITY_TYPE_EMPLOYEE, input.idEmployee, wfEditSettings))}
                                        newUser={false}
                                        id={null}
                                        securityRoles={null}
                                        edit={false}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </PermissionsGate>
                    :
                    null
            }
            <div className='mt-20'>
                <StyledDropzone
                    files={input.attachments}
                    setInput={setInput}
                    disabled={!(wfEditSettings?.edit || isEditableByOwner(ENTITY_TYPE_EMPLOYEE, input.idEmployee, wfEditSettings))}
                />
            </div>
            <div className='mt-20'>
                <Label for="attachedFiles" className='d-block'>{intl.get('timeOffApprove.attachedFiles.label')}</Label>
                {
                    input.attachments?.map((file, i) => (
                        <a type='button' className='text-decoration-underline d-block' key={i} href={file.path} download={file.path} >{file.path}<HiOutlineDownload className='ms-1 mb-1' /></a>
                    ))
                }
            </div>
            <div className='mt-20'>
                <Row className='justify-content-between'>
                    <Col md={6}>
                        <Label for="MandatoryFields">
                            {intl.get('timeOff.mandatory.field')}
                        </Label>
                    </Col>
                    <Col md={6}>

                        <WorkflowActionsBar loading={loading} viewMode='buttons' handleEditingMode={handleEditingMode} onActionClick={handleSave} id={input.idWorkflowState} disabled={JSON.stringify(errors) !== JSON.stringify({}) || input.idTimeOffRequestType === null  ? true : false} />

                    </Col>
                </Row>
            </div>

        </div>
    )
}

export default TimeOffRequestForm;