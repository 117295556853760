import React, { useState, useEffect } from 'react';
import { useApi } from '../api/ApiProvider';
import Api from '../axiosApi/api';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import intl from 'react-intl-universal';
import { getUserEntity } from 'common/UserEntityProvider';
import {Link} from "react-router-dom";
import { HiUserCircle } from 'react-icons/hi';


const SwitchProfile = ({ openProfileMenu }) => {

    const [input, setInput] = useState({
        idUser: null,
        user: {name: ''}
    })

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const userEmail = sessionStorage.getItem("username");

    const userDetails = getUserEntity();

    const fetchSwitchProfile = async (userEmail) => {
        await api.loginApi.apiVversionLoginEntitiesGet("1",sessionStorage.getItem('username')).then((response) => {
            if (response.data) {
                if (response.data?.errors === null){
                    sessionStorage.setItem('Users', JSON.stringify(response.data?.data));
                }
                else {
                    console.log(response.data?.Message);
                    if (response.data?.statusCode){
                        toast.error({ header: intl.get('switchProfile.toast.error.fetchSwitchProfile'), body: response.data?.Message }) ;
                    }
                }
            }
        }).catch((error) => {
            if(error.response) {
                console.log("Data :" , error.response.data);
                console.log("Status :" + error.response.status);
            } else if(error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('switchProfile.toast.error.fetchSwitchProfile'), body: error.message });
        });
    }

    const users = sessionStorage.getItem('Users') ? JSON.parse(sessionStorage.getItem('Users')) : {};
    const options = Object.keys(users)?.filter((idUser) => users[idUser]?.id != userDetails?.id)?.map(idUser => users[idUser]);

    useEffect(() => {
        !sessionStorage.getItem('Users') ? fetchSwitchProfile(userEmail) : null;
    }, [userEmail, sessionStorage.users]);

    const handleUserChange = (option) => {
        const inputVal = {
            ...input,
           user: option
        };
        setInput({ ...inputVal });
        handleSwitch(option);
    };

    const handleSwitch = (option) => {
        sessionStorage.setItem("UserEntity", JSON.stringify(option));
        location.reload();
    };

    return( 
        <> 
            <div className="user-options" style={{paddingLeft: "0.375rem"}}>
                <HiUserCircle className="me-1 mb-1"></HiUserCircle>
                <span>{
                    userDetails?.entityName
                } </span>
            </div>
            <div className={open ? "dropdown-menu dark language-menu" : "dropdown-menu language-menu"} aria-labelledby="languageMenu" style={openProfileMenu?{"display":"block"}:{"display":"none"}} >
                <h6>{intl.get('profileContent.userAccounts')}</h6>
                {
                    options.map((option) => (
                        <Link className="dropdown-item" to="#" id={option.entityName} onClick={() => handleUserChange(option)} >{option.entityName}</Link>
                    )) 
                }
            </div>
        </>
    )
};

export default SwitchProfile;