import React from 'react';
import { Form, Row, FormGroup, Label, Input } from 'reactstrap';
import CountrySelect from '../CountrySelect';
import intl from 'react-intl-universal';
import DatePickerDialog from '../DatePickerDialog';
import { uniformizeDate } from '../../common/utils';
import ErrorSummary from 'components/common/ErrorSummary';

const CalendarEventForm = ({ errors, input, handleCountryChange, selectedCountry, countries, handleInputChange, handleDate }) => {


    return (
        <Form>
            <FormGroup>
                <Row>
                    <ErrorSummary errors={errors} showEverything={true} ></ErrorSummary>
                </Row>
                <Row>
                    <Label for="addressCountry" className={`${errors.idCountry ? 'text-danger' : ''}`}>
                        {intl.get('calendarEventForm.country')} {errors.idCountry && (<span className='text-danger'>{errors.idCountry}</span>)}
                    </Label>
                    <CountrySelect 
                        handleCountryChange={handleCountryChange}
                        selectedCountry={selectedCountry}
                        countries={countries}
                        inputCountry={input.country.name}
                    />
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Label for="description" className={`${errors.description ? 'text-danger' : ''}`}>
                        {intl.get('calendarEventForm.description')} {errors.description && (<span className='text-danger'>{errors.description}</span>)}
                    </Label>
                    <Input
                        type="text"
                        id="description"
                        name="description"
                        placeholder={intl.get('calendarEventForm.placeholder.description')}
                        value={input.description}
                        onChange={handleInputChange}
                    />
                </Row>
            </FormGroup>
            <FormGroup>
                    <Label htmlFor="date" className={`${errors.date ? 'text-danger' : ''}`}>
                        {intl.get('calendarEventForm.date')} {errors.date && (<span className='text-danger'>{errors.date}</span>)}
                        </Label>
                    <DatePickerDialog
                        disabled={false}
                        inputName="date"
                        className={'timesheet-datepicker'}
                        selected={input.date ? uniformizeDate(input.date) : ''}
                        setSelected={handleDate}
                        required
                    />
            </FormGroup>
        </Form>
    )
}

export default CalendarEventForm;