import React, {useState, useEffect} from 'react';
import { Row, Col } from 'reactstrap';
import intl from 'react-intl-universal';
import { HiSearch } from 'react-icons/hi';
import { HiOutlineXCircle } from 'react-icons/hi';
import '../../scss/Roles/AssignedUsers.scss';

type UserProfile = {
    entityName: string;
    entityTypeName: string;
};

type GroupedUser = {
    email: string;
    profiles: UserProfile[];
};

const AssignedUsers = ({ users, closeModal = null, searchedUser, setSearchedUser, idNumber = null }) => {

    const [filteredUsers, setFilteredUsers ] = useState(users);
    const [groupedUsers, setGroupedUsers] = useState<Record<number, GroupedUser>>({});

    const groupProfilesByUser = (users: any[]): Record<number, GroupedUser> => {
        return users?.reduce((acc: Record<number, GroupedUser>, user) => {
            const { idUser, email, entityName, entityTypeName } = user;
            if (!acc[idUser]) {
                acc[idUser] = {
                    email,
                    profiles: []
                };
            }
            acc[idUser].profiles.push({ entityName, entityTypeName });
            return acc;
        }, {});
    };

    const handleSearchUser = () => {
        const filtered = users.filter(user => user.entityName.toLowerCase().includes(searchedUser.toLowerCase()));
        setFilteredUsers(filtered);
    };

    useEffect(() => {
        setFilteredUsers(users);
    }, [searchedUser, users]);

    useEffect(() => {
        const grouped = groupProfilesByUser(filteredUsers);
        setGroupedUsers(grouped);
    }, [filteredUsers]);

    return (
        idNumber ?
        <React.Fragment>
            <Row className='mb-3'>
                <Col md={10}>
                    <div className="d-flex col-3 input-group">
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder={intl.get('assignedUsers.searchBar.placeholder')} 
                            aria-label="Search"
                            value={searchedUser}
                            onChange={e => setSearchedUser(e.target.value)}
                        />
                        {
                            searchedUser ?
                            <button 
                                className="btn btn-outline-secondary close p-0" 
                                type="button"
                                onClick={() => {
                                    setSearchedUser('');
                                }}
                            >
                                <HiOutlineXCircle className='mx-1 iconButton'/>
                            </button> :
                            null
                        }
                    </div>
                </Col>
                <Col md={2} className='text-end'>
                    <button type="button" className="btn btn-primary" onClick={handleSearchUser} ><HiSearch className='mb-1'/> {intl.get('searchBar.button')}</button>
                </Col>
            </Row>
            <Row>
                <div className="table-responsive container-overflow">
                    <table className='table table-hover table-striped'>
                        <tbody className='maxHeight'>
                            {groupedUsers && Object.entries(groupedUsers).map(([idUser, user], i) => (
                                <React.Fragment key={i}>
                                    <tr>
                                        <td colSpan={2}>
                                            <td>User - ({user.email})</td>
                                        </td>
                                    </tr>
                                    {user.profiles.map((profile, j) => (
                                        <tr key={j}>
                                            <td className='width' style={{ paddingLeft: '3rem' }}>{profile.entityName} ({profile.entityTypeName})</td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Row>
        </React.Fragment> :
        <React.Fragment>
            <Row className='mb-4'>
                <h2 className='mb-1'>{intl.get('assignedUsers.title')}</h2>
            </Row>
            <Row className='mb-3'>
                <Col md={10}>
                    <div className="d-flex col-3 input-group">
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder={intl.get('assignedUsers.searchBar.placeholder')} 
                            aria-label="Search"
                            value={searchedUser}
                            onChange={e => setSearchedUser(e.target.value)}
                        />
                        {
                            searchedUser ?
                            <button 
                                className="btn btn-outline-secondary close p-0" 
                                type="button"
                                onClick={() => {
                                    setSearchedUser('');
                                }}
                            >
                                <HiOutlineXCircle className='mx-1 iconButton'/>
                            </button> :
                            null
                        }
                    </div>
                </Col>
                <Col md={2} className='text-end'>
                    <button type="button" className="btn btn-primary" onClick={handleSearchUser} ><HiSearch className='mb-1'/> {intl.get('searchBar.button')}</button>
                </Col>
            </Row>
            <Row>
                <div className="table-responsive container-overflow">
                    <table className='table table-hover table-striped'>
                        <tbody>
                            {Object.entries(groupedUsers).map(([idUser, user], i) => (
                                <React.Fragment key={i}>
                                    <tr>
                                        <td colSpan={2}>
                                            <td>User - ({user.email})</td>
                                        </td>
                                    </tr>
                                    {user.profiles.map((profile, j) => (
                                        <tr key={j}>
                                            <td style={{ paddingLeft: '3rem' }}>{profile.entityName} ({profile.entityTypeName})</td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Row>
            <div className="d-grid d-md-flex justify-content-md-end">
                <button type="button" className="btn btn-outline-danger" onClick={closeModal} >{intl.get('close.button')}</button>
            </div>
        </React.Fragment>
    )
}

export default AssignedUsers;
