/* tslint:disable */
/* eslint-disable */
/**
 * Intranet - Workflow API
 * Workflows
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { RequestArgs, BaseAPI, RequiredError } from '../base';
import { ModuleSearchResponseResponse } from '../models';
import { StringResponse } from '../models';
import { Settings } from 'common/settings';
/**
 * ModuleApi - axios parameter creator
 * @export
 */
const BASE_PATH = Settings.getInstance().BASE_API_URL_WORKFLOW;
export const ModuleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets all the items in a list.
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionModuleAllGet: async (version: string, pageNum?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling apiVversionModuleAllGet.');
            }
            const localVarPath = `/api/v{version}/Module/all`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            // authentication Userentity required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Userentity")
                    : await configuration.apiKey;
                localVarHeaderParameter["Userentity"] = localVarApiKeyValue;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModuleApi - functional programming interface
 * @export
 */
export const ModuleApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets all the items in a list.
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionModuleAllGet(version: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ModuleSearchResponseResponse>>> {
            const localVarAxiosArgs = await ModuleApiAxiosParamCreator(configuration).apiVversionModuleAllGet(version, pageNum, pageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ModuleApi - factory interface
 * @export
 */
export const ModuleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets all the items in a list.
         * @param {string} version 
         * @param {number} [pageNum] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionModuleAllGet(version: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<AxiosResponse<ModuleSearchResponseResponse>> {
            return ModuleApiFp(configuration).apiVversionModuleAllGet(version, pageNum, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModuleApi - object-oriented interface
 * @export
 * @class ModuleApi
 * @extends {BaseAPI}
 */
export class ModuleApi extends BaseAPI {
    /**
     * 
     * @summary Gets all the items in a list.
     * @param {string} version 
     * @param {number} [pageNum] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModuleApi
     */
    public async apiVversionModuleAllGet(version: string, pageNum?: number, pageSize?: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<ModuleSearchResponseResponse>> {
        return ModuleApiFp(this.configuration).apiVversionModuleAllGet(version, pageNum, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}
