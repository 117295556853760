import React, { useState } from 'react';

import Modal, { ModalSizes } from "../Modal";
import intl from 'react-intl-universal';
import { Col, FormGroup, Label, Input } from 'reactstrap';
import { HiOutlineSave } from 'react-icons/hi';

export type WorkflowCommentsOnSubmitProps = {
    input: string
}
export type WorkflowCommentsModalProps = {
     isOpen:boolean, 
     closeModal:any,
     onSubmit: WorkflowCommentsOnSubmitProps 
    };

const WorkflowCommentsModal = ({ isOpen, onCloseModal, onSubmit }) => {
    
    const [input, setInput] = useState<string>('');

    const handleSave = async () => {
        if (onSubmit) {
            onSubmit(input); 
        }
        onCloseModal();
    };

    const handleInputChange = (e) => {
        setInput( e.target.value);
    };

    return (
        <Modal isOpen={isOpen} closeModal={onCloseModal} size={ModalSizes.small} height="auto">
            
        <div className="container">
            <div className="card-header">
                <h2 className="text-start">{intl.get('timeOffReject.title')}</h2>
            </div>
            <div className="card-body">
                <FormGroup>
                    <Col md={12}>
                        <Label for="description" className="d-flex text-start">
                            {intl.get('timeOffReject.description')}
                        </Label>
                        <Input
                            id="description"
                            name="description"
                            placeholder={intl.get('timeOffReject.placeholder.description')}
                            type="textarea"
                            rows="5"
                            onChange={handleInputChange}
                        />
                    </Col>
                </FormGroup>
            </div>
            <div>
                <Col md={12} className="d-grid gap-2 d-md-flex justify-content-md-end">
                    <button type="button" className="btn btn-outline-dark" onClick={onCloseModal}>
                        {intl.get('cancel.button')}
                    </button>
                    <button 
                        type="submit" 
                        className="btn btn-primary" 
                        onClick={handleSave} >
                        <HiOutlineSave className="mb-1" /> {intl.get('save.button')}
                    </button>
                </Col>
            </div>
        </div>
        </Modal>
    );
};

export default WorkflowCommentsModal;
  
  
