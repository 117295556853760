import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import intl from 'react-intl-universal';
import HRCalendarNavbar from './HRCalendarNavbar';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from '../../components/ToastMessages/ToastMessageProvider';
import GenericCalendar from './GenericCalendar';
import Spinner from '../Spinner';
import { HiOutlineCalendar } from 'react-icons/hi';
import { handleAPIError } from 'common/errorHandler';

export type CalendarInput = {
    idCountry: number | null
    country: any,
    idTimeOffRequestType: null,
    queryTerm?: string | null,
    currentPage?: number,
	sortValue?: string | null,
	orderValue?: string | null,
};

export type SearchResult = {
	totalRecords: number | null,
	queryResult: any
};

const HRCalendar = ({ HRView = false }) => {

    const [input, setInput] = useState<CalendarInput | null>({
        idCountry: null,
        country: {name: ''},
        idTimeOffRequestType: null,
        queryTerm: "",
        currentPage: 1,
		sortValue: "Name",
		orderValue: "asc",
    });

    const [searchResult, setSearchResult] = useState<SearchResult | null>({
        totalRecords: null,
        queryResult: null
	});

    const [timeOffEvents, setTimeOffEvents] = useState({
        totalRecords: null,
        queryResult: null
	});

    const [nonWorkingDayEvents, setNonWorkingDayEvents] = useState({
        totalRecords: null,
        queryResult: null
	});

    const [timeOffEvents2, setTimeOffEvents2] = useState({
        totalRecords: null,
        queryResult: null
	});

    const [nonWorkingDay, setNonWorkingDay] = useState(false);

    const [countries, setCountries] = useState(null);

    const [requestType, setRequestType] = useState(null);

    const [isCalendarEventDetailOpen, setIsCalendarEventDetailOpen] = useState(false);

    const closeCalendarEventDetail = () => setIsCalendarEventDetailOpen(false);

    const [loading, setLoading] = useState<boolean>(false);

    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    const location = useLocation()

    const api: Api = useApi();

    const toast = useToastMessageQueue();

	let limit: number = 100;

    const fetchEvents = async (term?: string, order?: string, f?: Array<string>) => {
        setLoading(true);
        if (input.idCountry) {
            f = [`country:${input.idCountry}`];
        }
        const response = await api.eventApi.apiVversionEventSearchGet("1", input.queryTerm, input.orderValue, f, {}).then((response) => {
            console.log(response.data.data)
            const renamedEvents = response?.data?.data?.queryResult?.map(event => ({
                ...event,
                start: new Date(event.from),
                end: new Date(event.to),
                from: undefined, 
                to: undefined
            }));
            setSearchResult({
                totalRecords: response?.data?.data?.totalRecords,
                queryResult: renamedEvents
            });
            const timeOffEvents = renamedEvents.filter(event => event.eventType === "TimeOff");
            const renamedTimeOffEvents = timeOffEvents.map(event => {
                const newEndDate = new Date(event.end);
                newEndDate.setDate(newEndDate.getDate() + 1);
                return { 
                    ...event, 
                    end: newEndDate, 
                    title: `${event.timeOffRequestTypeName} - ${event.employeeName} ${event.employeeLastName}`
                };
            });
            setTimeOffEvents({
                totalRecords: renamedTimeOffEvents.length,
                queryResult: renamedTimeOffEvents
            });
            const nonWorkingDayEvents = renamedEvents.filter(event => event.eventType === "NonWorkingDay");
            const renamedNonWorkingDayEvents = nonWorkingDayEvents.map(event => ({
                ...event,
                end: event.start
            }));
            setNonWorkingDayEvents({
                totalRecords: renamedNonWorkingDayEvents.length,
                queryResult: renamedNonWorkingDayEvents
            });
            console.log("Success");
            setLoading(false);
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({ ...errors });
        });
    };

    const fetchRequests = async (term?: string, pageNum?: number, pageSize?: number, sort?: string, order?: string, f?: Array<string>) => {
        if (input.idTimeOffRequestType) {
            f = [`type:${input.idTimeOffRequestType}`];
        };
        const response = await api.timeOffApi.apiVversionTimeOffSearchGet("1", input.queryTerm, input.currentPage, limit, input.sortValue, input.orderValue, f, {}).then((response) => {
            console.log(response.data.data)
            if (response.data.data) {
                const renamedEvents = response?.data?.data?.queryResult?.map(event => ({
                    ...event,
                    start: new Date(event.from),
                    end: new Date(event.to),
                    from: undefined, 
                    to: undefined
                }));
                const renamedTimeOffEvents = renamedEvents.map(event => {
                    const newEndDate = new Date(event.end);
                    newEndDate.setDate(newEndDate.getDate() + 1);
                    return { 
                        ...event, 
                        end: newEndDate, 
                        title: `${event.timeOffRequestTypeName} - ${event.employeeName} ${event.employeeLastName}`
                    };
                });
                setTimeOffEvents2({
                    totalRecords: renamedTimeOffEvents.length,
                    queryResult: renamedTimeOffEvents
                });
            } else {
                setTimeOffEvents2({
                    totalRecords: null,
                    queryResult: null
                });
            };
            setLoading(false);
            console.log("Success");
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({ ...errors });
        });
    };

    const fetchCountries = async () => {
        const response = await api.addressApi.apiVversionAddressCountryAllGet("1", {}).then((response) => {
            if (response.data) {
                setCountries(response.data.data);
                console.log("Success");
            };
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({ ...errors });
        });
    };

    const fetchRequestType = async () => {
        const response = await api.timeOffRequestTypeApi.apiVversionTimeOffRequestTypeAllGet("1", input.currentPage, limit, {}).then((response) => {
            if (response.data.data) {
                setRequestType({
                    totalRecords: response.data?.data?.totalRecords,
                    queryResult: response.data?.data?.queryResult
                });
            } else {
                setRequestType({
                    totalRecords: null,
                    queryResult: null
                });
            };
            console.log("Success");
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({ ...errors });
        });
    };

    const updateEvents = async () => {
        setLoading(true);
        try {
            await fetchEvents();
            await fetchRequests();
        } catch (error) {
            console.error("Error updating events:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEvents();
        fetchRequests();
        fetchCountries();
        fetchRequestType();
        if (location.pathname === '/Calendar/Holidays') {
            setNonWorkingDay(true);
        }
    }, [location.pathname, input]);

    const handleCountryChange = (e) => {
        if (e !== null) {
            const inputVal = {
                ...input,
                idCountry: e.id,
                country: {name: e.name}
            };
            setInput({ ...inputVal });
        } else {
            const inputVal = {
                ...input,
                idCountry: null,
                country: {name: ''}
            };
            setInput({ ...inputVal });
        }
    };

    const handleCalendarEventDetailOpen = () => {
        setIsCalendarEventDetailOpen(true);
    };

    const onSearch = (queryString, idRequest) => {
        setInput({ ...input, queryTerm: queryString, idTimeOffRequestType: idRequest, currentPage: 1 });
    };

    const handleMyPTO = () => {
        navigate('/timeOff');
    };

    const handleManagePTO = () => {
        navigate('/timeOffHR');
    };

    return (
        <div className="container">
            <div className='card mt-4'>
                <div className="card-header">
                    {
                        nonWorkingDay ?
                        <h2 className="title">{intl.get('hRCalendar.header.holidays')}</h2> :
                        <>
                            <h2 className="title">{intl.get('hRCalendar.header')}</h2>
                            {
                                HRView ?
                                <a type='button' className="link-secondary newEmployeeSize" onClick={handleManagePTO}>{intl.get('hRCalendar.list')}<HiOutlineCalendar className='ms-1 mb-1' /></a>
                                :
                                <a type='button' className="link-secondary newEmployeeSize" onClick={handleMyPTO}>{intl.get('hRCalendar.list')}<HiOutlineCalendar className='ms-1 mb-1' /></a>
                            }
                        </>
                    }
                </div>
                <div className='card-body '>
                    <HRCalendarNavbar 
                        handleCountryChange = {handleCountryChange}
                        selectedCountry={input?.country.name}
                        countries={countries}
                        input={input}
                        handleCalendarEventDetailOpen={handleCalendarEventDetailOpen}
                        isCalendarEventDetailOpen={isCalendarEventDetailOpen}
                        closeCalendarEventDetail={closeCalendarEventDetail}
                        nonWorkingDay={nonWorkingDay}
                        requestType={requestType?.queryResult}
                        onSearch={onSearch}
                        updateEvents={updateEvents}
                    />
                    {
                        loading === true ?
                        <Spinner /> :
                        <GenericCalendar 
                            events={nonWorkingDay ? nonWorkingDayEvents?.queryResult : timeOffEvents2?.queryResult} 
                            nonWorkingDay={nonWorkingDay}
                            updateEvents={updateEvents}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default HRCalendar;