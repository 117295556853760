import React, { useEffect, useState } from 'react';
import { Chip, TextField, Box, Typography, Paper } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { MdNotStarted, MdStopCircle } from "react-icons/md";
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import intl from 'react-intl-universal';
import '../../scss/Workflow/EditableNode.scss';
import Spinner from 'components/Spinner';
interface ChipSelectorProps {
  label: string;
  value: string[];
  onChange: any;
  scopes: any;
}

const ChipSelector: React.FC<ChipSelectorProps> = ({ label, onChange, value, scopes }) => {
  const [selectedScopes, setSelectedScopes] = useState<any>(value);


  return (
    <Autocomplete
     
      multiple
      options={scopes?.queryResult}
      value={!!selectedScopes?selectedScopes.map(id => scopes?.queryResult.find(scope => scope.id.toString() === id)):[]}
      onChange={(event, newValue) => {
        console.log(`Autocomplete onChange ${newValue}`)
        setSelectedScopes(newValue);
        if (!!onChange) onChange(newValue);
      }}
      
      getOptionLabel={(scope) => scope?.scope}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            
            label={option?.scope}
            color="primary"  
            variant="outlined" 
            {...getTagProps({ index })}
          />
        ))
      }
      
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label} placeholder="Select scopes" />
      )}
    />
  );
};

interface EditableNodeProps {
  id: string;
  internalId: number;
  data: { label: string; final?: boolean; initial?: boolean, editScopes?: string[], viewScopes?: string[], deleteScopes?: string[] };
  onChange: (id: string, data: { label: string; final?: boolean; initial?: boolean, editScopes?: string[], viewScopes?: string[], deleteScopes?: string[] }) => void;
  disabled?: boolean;
  allScopes: any;
  //onInitialChange:  (id: string) => void;
}

const EditableNode: React.FC<EditableNodeProps> = ({ id, internalId, data, onChange, disabled, allScopes }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [label, setLabel] = useState(data.label);
  const [final, setFinal] = useState(data.final);
  const [initial, setInitial] = useState(data.initial);
  const [showContent, setShowContent] = useState(false);
  const [scopes, setScopes] = useState(allScopes);
  const [loading, setLoading] = useState(true);
  const [editScopes, setEditScopes] = useState<string[]>(data.editScopes);
  const [viewScopes, setViewScopes] = useState<string[]>(data.viewScopes);
  const [deleteScopes, setDeleteScopes] = useState<string[]>(data.deleteScopes);
  const api: Api = useApi();
  const toast = useToastMessageQueue();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setLabel(event.target.value);
  const handleFinalChange = (event: React.ChangeEvent<HTMLInputElement>) => { setFinal(event.target.checked); setInitial(false) };
  const handleInitialChange = (event: React.ChangeEvent<HTMLInputElement>) => { setInitial(event.target.checked); setFinal(false) };
  const handleEditScopesChange = (newValue: string[]) => { setEditScopes(newValue); }
  const handleViewScopesChange = (newValue: string[]) => setViewScopes(newValue);
  const handleDeleteScopesChange = (newValue: string[]) => setDeleteScopes(newValue);

  const handleDoubleClick = () => {
    setIsEditing(!isEditing);
    if (!isEditing) {
      setTimeout(() => setShowContent(true), 1200); // delay matching the transition duration
    } else {
      setShowContent(false);
      var data = { label: label, final: final, initial: initial, editScopes: editScopes, viewScopes: viewScopes, deleteScopes: deleteScopes };
      onChange(id, data);
    }
  };

  const fetchScopes = async () => {
    setLoading(true);
    const response = await api.scopeApi.apiVversionScopeAllGet("1", 1, 100, {}).then((response) => {
      if (response.data.data === null) {
        setScopes({
          totalRecords: 0,
          queryResult: []
        });
      } else {
        setScopes({
          totalRecords: response.data?.data?.totalRecords,
          queryResult: response.data?.data?.queryResult
        });
      };
      setLoading(false);
      console.log("Success");
    }).catch((error) => {
      if (error.response) {
        console.log("Data :", error.response.data);
        console.log("Status :" + error.response.status);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
      toast.error({ header: intl.get('roleDetail.toast.error.fetchScopes'), body: error.message });
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchScopes();
  }, []);


  return (
    <div 
      onDoubleClick={handleDoubleClick}
      className="flex flex-col border border-solid border-gray-200 h-full rounded-2xl bg-white/70 shadow-[0_7px_9px_0_rgba(0,0,0,0.02)]"
      style={{
        border: '1px solid #777',
        padding: '10px',
        borderRadius: '5px',
        background: 'white',
        width: isEditing ? '800px' : '150px',
        height: isEditing ? '400px' : '40px',
        minWidth: '150px',
        minHeight: '40px',
        textAlign: isEditing ? 'left' : 'center',
        transition: 'width 1s, height 1s'
      }}
    >{initial ? <div className='initialMarker'><MdNotStarted size={30} /></div> : null}
      {final ? <div className='finalMarker'><MdStopCircle size={30} /></div> : null}
      {isEditing && showContent ? (

        loading ?
          <Spinner /> :
          <>
          <div className="text-xs px-3 py-2 border-b border-solid border-gray-200 font-mono font-semibold rounded-t-2xl">
                <label htmlFor="name" className={`form-label}`}>
                  {intl.get('editableNode.placeholder.nodeName')}
                </label>
                <input
                  type="text"
                  className='form-control'
                  id="name"
                  name="name"
                  placeholder={intl.get('editableNode.placeholder.label')}
                  value={label}
                  onChange={handleChange}
                  disabled={disabled}
                  autoFocus
                ></input></div>
              
            <div className="row">
              <div className='d-inline-flex col-12 col-md-5 flex-column'>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2" style={{ textAlign: 'center' }}>
                <label htmlFor="initial" className="mx-2">
                  {intl.get('editableNode.placeholder.start')}
                </label>
                <input
                  type="checkbox"
                  className="form-control my-3 mx-auto"
                  onChange={handleInitialChange}
                  checked={initial}
                  disabled={disabled}
                  name="initial"
                ></input>
              </div>
              <div className="col-md-2" style={{ textAlign: 'center' }}>
                <label htmlFor="final" className="mx-2">
                  {intl.get('editableNode.placeholder.final')}
                </label>
                <input
                  type="checkbox"
                  className="form-control my-3 mx-auto"
                  onChange={handleFinalChange}
                  checked={final}
                  name="final"
                  disabled={disabled}
                ></input>
              </div>
            </div>
            <Box display="flex" mt={1} flexDirection="column">
              <Box flex={1} textAlign="center" mt={1}>

                <ChipSelector label="View Scopes" value={viewScopes} onChange={handleViewScopesChange} scopes={scopes} />
              </Box>
              <Box flex={1} textAlign="center" mt={1}>

                <ChipSelector label="Edit Scopes" value={editScopes} onChange={handleEditScopesChange} scopes={scopes} />
              </Box>
              <Box flex={1} textAlign="center" mt={1}>

                <ChipSelector label="Delete Scopes" value={deleteScopes} onChange={handleDeleteScopesChange} scopes={scopes} />
              </Box>
            </Box>
          </>
      ) : (
        <div>{label}</div>
      )}
    </div>
  );
};

export default EditableNode;
