import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import RoleForm from './RoleForm';
import '../../scss/Roles/RoleDetail.scss';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { CreateSecurityRoleCommand, UpdateSecurityRoleCommand } from 'axiosApi/models';
import {PermissionsGate} from '../../components/PermissionsGate';
import { SCOPES, ROLES } from "../../common/permissions";
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { ButtonWithOptions, ButtonWithOptionsType } from "components/common/ButtonWithOptions";
import { HiArrowLeft, HiOutlineSave, HiPencilAlt } from 'react-icons/hi';
import RequiredField from '../RequiredField';
import intl from 'react-intl-universal';
import { Row } from 'reactstrap';
import {USER_STATUS} from '../../common/constants';
import { SelectChangeEvent } from '@mui/material/Select';
import Spinner from '../Spinner';

export type RoleDetailInput = {
    id?: number | null,
    name?: string | null,
    description?: string | null,
    active?: boolean | null,
    idActive?: number | null,
    scopes?: any,
    idUsers?: any,
    users?: any,
    idUserSelected?: number | null,
    userSelected?: any
};

const RoleDetail = () => {

    const [input, setInput] = useState<RoleDetailInput | null>({
        id: null,
        name: '',
        description: '',
        active: null,
        idActive: null,
        scopes: [],
        idUsers: null,
        users: null,
        idUserSelected: null,
        userSelected: null
    });

    const [scopes, setScopes] = useState(null);

    const api: Api = useApi();

    const navigate = useNavigate();

    const toast = useToastMessageQueue();

    const { id } = useParams();
    const idNumber = parseInt(id);

    const [errors, setErrors] = useState({});

    const [disabled, setDisabled] = useState(false);

    const [disabledNew, setDisabledNew] = useState(false);

    const [loading, setLoading] = useState<boolean>(false);

    const fetchRoleDetail = async (idNumber: number) => {
        setLoading(true);
        const response = api.securityRoleApi.apiVversionSecurityRoleIdGet(idNumber, "1", {}).then((response) => {
            if(response.data) {
                const idUsers = response.data?.data?.users?.map(user => user.id);
                setInput({
                    ...input,
                    id: idNumber,
                    name: response.data?.data?.name,
                    description: response.data?.data?.description ? response.data?.data?.description : '',
                    active: response.data?.data?.active,
                    idActive: response.data?.data?.active === true ? 1 : 2,
                    scopes: response.data?.data?.scopes,
                    users: response.data?.data?.users,
                    idUsers: idUsers
                });
                setLoading(false);
                console.log("Success");
            };
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('roleDetail.toast.error.fetchRoleDetail'), body: error.message });
            setLoading(false);
        });
    };

    const fetchScopes = async () => {
        setLoading(true);
        const response = await api.scopeApi.apiVversionScopeAllGet("1", 1, 100, {}).then((response) => {
            if (response.data.data === null) {
				setScopes({
					totalRecords: null,
					queryResult: null
				});
			} else {
                setScopes({
                    totalRecords: response.data?.data?.totalRecords,
                    queryResult: response.data?.data?.queryResult
                });
            };
            setLoading(false);
            console.log("Success");
        }).catch((error) => {
			if (error.response) {
				console.log("Data :", error.response.data);
				console.log("Status :" + error.response.status);
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log("Error", error.message);
			}
            toast.error({ header: intl.get('roleDetail.toast.error.fetchScopes'), body: error.message });
			setLoading(false);
		});
    };

    useEffect(() => {
        fetchScopes();
        if (idNumber) {
            fetchRoleDetail(idNumber);
            setDisabled(true);
        };
        setDisabledNew(true);
    }, [idNumber]);

    const handleInputChange = function (e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
        setErrors(validate({
            ...input,
            [e.target.name]: e.target.value
        }));
    };

    const validate = function (input: RoleDetailInput) {
        setDisabledNew(false);
        let errors: any = {};
        if (!input.name) {
            errors.name = intl.get('validate.errors.isRequired');
        }
        return errors;
    };

    const handleSubmit = async (saveAndEdit: boolean = false) => {
        setLoading(true);
        const errors = validate(input);
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            if (idNumber) {
                let cmd: UpdateSecurityRoleCommand = {
                    name: input.name,
                    description: input.description,
                    scopes: input.scopes,
                    id: idNumber,
                    active: input.active,
                };
                const response = await api.securityRoleApi.apiVversionSecurityRolePut("1", cmd, {}).then((response) => {
                    if (saveAndEdit) {
                        setDisabled(true);
                    } else {
                        navigate('/roles');
                    }
                    toast.success({ body: intl.get('roleDetail.toast.success.handleSubmit')});
                    setLoading(false);
                }).catch((error) => {
                    if (error.response) {
                        console.log("Data :", error.response.data);
                        console.log("Status :" + error.response.status);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                    toast.error({ header: intl.get('roleDetail.toast.error.handleSubmit'), body: error.message });
                    setLoading(false);
                });
            } else {
                let cmd: CreateSecurityRoleCommand = {
                    name: input.name,
                    description: input.description,
                    active: input.active,
                    scopes: input.scopes
                };
                const response = await api.securityRoleApi.apiVversionSecurityRolePost("1", cmd, {}).then((response) => {
                    toast.success({ body: intl.get('roleDetail.toast.success.handleSubmit.add')});
                    setLoading(false);
                    if (saveAndEdit) {
                        navigate(`/role/detail/${response.data.data.id}`);
                    } else {
                        navigate('/roles');
                    };
                }).catch((error) => {
                    if (error.response) {
                        console.log("Data :", error.response.data);
                        console.log("Status :" + error.response.status);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                    toast.error({ header: intl.get('roleDetail.toast.error.handleSubmit.add'), body: error.message });
                    setLoading(false);
                });
            };
        };
    };

    const handleBackClick = (e) => {
        navigate('/roles');
    };

    const handleSaveClick = () => {
        handleSubmit(true);
    };

    const handleSaveCloseClick = () => {
        handleSubmit(false);
    };

    const handleEditMode = (e) => {
        e.preventDefault();
        console.log('handleEditMode', input, disabled);
        if (idNumber) {
            fetchRoleDetail(idNumber);
        }
        setErrors({});
        setDisabled(!disabled);
    };

    const handleStatusChange = (e) => {
        const inputVal = {
            ...input,
            idActive: e.id,
            active: e.status === "Active" ? true : false
        };
        setInput({ ...inputVal });
    };

    const handleScopeChange = (event, id) => {
        setInput((prevInput) => ({
            ...prevInput,
            scopes: event
        }));
    };

    return (
        <div className='container'>
            <Row className='justify-content-center'>
                <div className={`${idNumber ? 'card mt-4' : 'card mt-4 col-7'}`}>
                    <div className="card-header">
                        {
                            idNumber ? 
                            <>
                                <h2 className="title mb-0">{intl.get('roleDetail.header')}</h2>
                            </> :
                            <h2 className="title mb-0">{intl.get('newRole.header')}</h2>
                        }
                    </div>
                    {
                        loading ?
                        <Spinner /> :
                        <div className='card-body'>
                            <RoleForm 
                                errors={errors} 
                                input={input}
                                handleInputChange={handleInputChange} 
                                handleStatusChange={handleStatusChange} 
                                status={USER_STATUS} 
                                disabled={disabled} 
                                scopes={scopes?.queryResult} 
                                idNumber={idNumber} 
                                users={input.users} 
                                handleScopeChange={handleScopeChange}
                                selectedScopes={input.scopes}
                            />
                            <RequiredField/>
                            <div className='d-flex justify-content-end my-3'>
                                {
                                    !idNumber && !disabled ?
                                    <>
                                        <button type='button' className='btn btn-outline-dark me-2' id='return' onClick={handleBackClick}>
                                            <HiArrowLeft/>
                                        </button>
                                            <ButtonWithOptions options={[/*{ title: 'Save', onClick: handleSaveClick, disabled: false, type: ButtonWithOptionsType.item }, */{ title: `${intl.get('save&close.button')}`, onClick: handleSaveCloseClick, disabled: Object.keys(errors).length > 0 || disabledNew, type: ButtonWithOptionsType.item }]} outline={true} disabled={Object.keys(errors).length > 0 || disabledNew} className="btn btn-primary" onClick={handleSaveClick} ><HiOutlineSave className='mb-1'/> {intl.get('save.button')}</ButtonWithOptions>
                                    </> : null
                                }
                                {
                                    idNumber && disabled ?
                                    <>
                                        <button type='button' className='btn btn-outline-dark me-2' id='return' onClick={handleBackClick}>
                                            <HiArrowLeft/>
                                        </button>
                                        <button type='button' className='btn btn-primary me-2' id='edit' onClick={handleEditMode}>   
                                            <HiPencilAlt className='mb-1' /> {intl.get('edit.button')}
                                        </button>
                                    </> : null
                                }
                                {
                                    idNumber && !disabled ?
                                    <>
                                        <button type='button' className='btn btn-outline-dark me-2' id='edit' onClick={handleEditMode}>   
                                            {intl.get('cancel.button')}
                                        </button>
                                            <ButtonWithOptions options={[/*{ title: 'Save', onClick: handleSaveClick, disabled: false, type: ButtonWithOptionsType.item }, */{ title: `${intl.get('save&close.button')}`, onClick: handleSaveCloseClick, disabled: Object.keys(errors).length > 0 || disabled, type: ButtonWithOptionsType.item }]} outline={true} disabled={Object.keys(errors).length > 0 || disabled} className="btn btn-primary" onClick={handleSaveClick} ><HiOutlineSave className='mb-1'/> {intl.get('save.button')}</ButtonWithOptions>
                                    </> : null
                                }
                            </div>
                        </div>
                    }
                </div>
            </Row>
        </div>
    )
}

export default RoleDetail;