import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from '../ToastMessages/ToastMessageProvider';
import { UpdateTimeOffCommand } from '../../axiosApi/models/update-time-off-command';
import intl from 'react-intl-universal';
import '../../scss/Time Off/TimeOffRow.scss';
import Chip from '@mui/material/Chip';
import { HiDocument, HiEye } from 'react-icons/hi';
import WorkflowActionsBar from '../Workflow/WorkflowActionsBar';
import { handleAPIError } from 'common/errorHandler';

const TimeOffRow = ({ className, id, employee, status, request, from, to, date, idWorkflowState, title, idEmployee, changeLog, idTimeOffRequestType, fetchRequestsHR, fetchRequestsSimpleUser, HRView }) => {

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const navigate = useNavigate();

    const [comments, setComments] = useState({
        comments: ""
    });

    const [errors, setErrors] = useState({}); // FIXME: -

    const [loading, setLoading] = useState<boolean>(false);

    const color = status === "Pending" || status === "Pendiente" ? "warning" : status === "Approved" || status === "Aprobado" ? "success" : status === "Observed" || status === "Observado" ? "default" : status === "Open" || status === "Abierto" ? "primary" : status === "Reopen" || status === "Reabrir" ? "secondary" : "error";

    const handleTimeOffDetail = (HRView: boolean) => {
        if (HRView) {
            navigate(`/timeOffRequestHR/detail/${id}`);
        } else {
            navigate(`/timeOffRequest/detail/${id}`);
        }
    };

    function formatDateISO(dateString) {
        const [day, month, year] = dateString.split('/');
        const dateISO = new Date(`${year}-${month}-${day}T00:00:00`);
        return dateISO;
    };

    const handleSave = async (stateId, comments) => {
        setLoading(true);
        let cmdJSON: UpdateTimeOffCommand = {
            id: id,
            from: formatDateISO(from),
            to: formatDateISO(to),
            title: title,
            idEmployee: idEmployee,
            idWorkflowState: stateId,
            changeLog: changeLog,
            idTimeOffRequestType: idTimeOffRequestType,
            comments: comments
        };
        let cmd = JSON.stringify(cmdJSON);
        const response = await api.timeOffApi.apiVversionTimeOffPutForm("1", cmd, [], [], {}).then((response) => {
            if (response.data) {
                toast.success({ body: intl.get('calendarEvent.toast.success.handleSave')});
                setLoading(false);
                if (HRView) {
                    fetchRequestsHR();
                } else {
                    fetchRequestsSimpleUser();
                }
            };
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({ ...errors });
        });
    };

    return (
        <tr className={className}>
            <td>{employee}</td>
            <td>
                <Chip label={status} color={color} className={color === "default" ? 'observed' : ''}/>
            </td>
            <td>{request}</td>
            <td>{date}</td>
            <td className='text-center'>
                <WorkflowActionsBar viewMode='icons' onActionClick={handleSave} id={idWorkflowState} loading={loading}/>
            </td>
            <td className='text-center'>
                <a className='td-icon mx-1' title={intl.get('timeOffRow.documentButton.title')}>
                    <HiDocument/>
                </a>
                <a className='td-icon mx-1' title={intl.get('timeOffRow.detailButton.title')}>
                    <HiEye onClick={() => handleTimeOffDetail(HRView)}/>
                </a>
            </td>
        </tr>
    )
}

export default TimeOffRow;