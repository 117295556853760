import { SCOPES } from 'common/permissions';
import { PermissionsGate } from 'components/PermissionsGate';
import React from 'react';

const CurrentAssignationsRow = ({ projectName, hourlyRate, monthlyRate, roleName, dueDate, startDate, allocation, allocationType }) => {

    return (
        <>
            {
                projectName ?
                <tr>
                    <td>{projectName}</td>
                    <td className='text-center'><PermissionsGate viewScopes={[SCOPES['billing.reader']]} editScopes={[SCOPES['billing.reader']]} viewRoles={[]} editRoles={[]}>   {hourlyRate}</PermissionsGate></td>
                    <td className='text-center'><PermissionsGate viewScopes={[SCOPES['billing.reader']]} editScopes={[SCOPES['billing.reader']]} viewRoles={[]} editRoles={[]}>  {monthlyRate}</PermissionsGate></td>
                    <td className='text-center'>{allocation}{allocationType==1?' %': ' hs.'}</td>
                    <td>{roleName}</td>
                    <td className='text-center'>{startDate} - {dueDate}</td>
                </tr> :
                null
            }
        </>
    )
}

export default CurrentAssignationsRow;