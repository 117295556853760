import { JobType } from 'axiosApi/models';
import React, { FC } from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';
import intl from 'react-intl-universal';
import { sortCollection } from '../../common/utils';
import { SORT_TYPES } from '../../common/constants';

type JobSelectProps = {
  handleJobTypeChange: any,
  selectedJobType: any,
  jobTypes: any,
  className?: string,
  disabled: boolean
};

const JobTypeSelect: FC<JobSelectProps> = ({ handleJobTypeChange, selectedJobType, jobTypes, className, disabled }) => {

  return (
    <>
      <Select
        id='idJobType'
        name='idJobType'
        className={`my-2 ${className}`}
        getOptionLabel={e => intl.get(e.label)}
        getOptionValue={e => e.id}
        placeholder={intl.get('jobTypeSelect.placeholder')}
        value={disabled ? '' : (jobTypes != null && selectedJobType != null) ? (jobTypes.find((jobType: JobType) => jobType.id === selectedJobType)) : ''}
        onChange={handleJobTypeChange}
        isLoading={jobTypes == null}
        options={jobTypes}
        isDisabled={disabled}
      />
    </>
  );
}

export default JobTypeSelect;