import React, {useState} from "react";
import AssignedUsers from "./AssignedUsers";
import Modal, { ModalSizes } from "../Modal";

const ModalUsers = ({ isOpen, closeModal, users }) => {

    const [searchedUser, setSearchedUser] = useState<string>('');

    const closeModalUsers = () => {
        setSearchedUser('');
        closeModal();
    };

    return (
        <Modal isOpen={isOpen} closeModal={closeModalUsers} size={ModalSizes.small} height="auto">
            <AssignedUsers users={users} closeModal={closeModalUsers} searchedUser={searchedUser} setSearchedUser={setSearchedUser}/>
        </Modal>
    )
}

export default ModalUsers;