import React, { ChangeEventHandler, MouseEventHandler, useEffect, useRef, useState } from 'react';

import { isValid, parse } from 'date-fns';
import FocusTrap from 'focus-trap-react';
import { DayPicker } from 'react-day-picker';
import { usePopper } from 'react-popper';
import { BsCalendarDate } from "react-icons/bs";
import { formatDate, getCurrentDateFormat, newDateAsUTC, uniformizeDate } from 'common/utils';
import moment from 'moment';
type DatePickerDialogProps = {
  selected: Date | string,
  setSelected: Function,
  className: string,
  inputName: string,
  required: boolean,
  disabled: boolean
}



export default function DatePickerDialog({ selected, setSelected, className, inputName, required, disabled }: DatePickerDialogProps) {

  
  const [selectedDate, setSelectedDate] = useState(selected);
  const [inputValue, setInputValue] = useState<string>('');
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const popperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start'
  });

  const closePopper = () => {
    setIsPopperOpen(false);
    buttonRef?.current?.focus();
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.currentTarget.value;
    setInputValue(value);
  
    const date = moment(value, getCurrentDateFormat(), true);
    if (date.isValid()) {
      setSelected(date.toDate());
      setSelectedDate(date.toDate());
    }
  };

  const handleButtonClick = () => {
    setIsPopperOpen(true);
  };

  const handleDaySelect = (date: Date) => {
    if (date !== undefined) {
      const formatedDate = formatDate(date);
      setSelected(formatedDate);
      if (date) {
        setInputValue(formatedDate);
        closePopper();
      } else {
        setInputValue('');
      }
    }
  };

  useEffect(() => {
    if (selected) {
      setSelectedDate(selected);
      setInputValue(formatDate(selected as Date));
    } else {
      setInputValue('');
    }
  }, [selected]);

  return (
    <div className={className}>
      <div className='position-relative' ref={popperRef}>
        <input
          disabled={disabled}
          name={inputName}
          type="text"
          placeholder={ getCurrentDateFormat()}
          value={inputValue}
          onChange={handleInputChange}
          className="input-reset form-control"
          required={required}
        />
        <button
          ref={buttonRef}
          type="button"
          className="button-reset button-icon"
          aria-label="Pick a date"
          onClick={handleButtonClick}
          disabled={disabled}
        >
          <BsCalendarDate></BsCalendarDate>
        </button>
      </div>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
            fallbackFocus: buttonRef.current
          }}
        >
          <div
            tabIndex={-1}
            style={popper.styles.popper}
            className="dialog-sheet"
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
          >
            <DayPicker
              initialFocus={isPopperOpen}
              mode="single"
              defaultMonth={uniformizeDate(selectedDate)}
              selected={uniformizeDate(new Date(inputValue))}
              onSelect={e => handleDaySelect(e)}
            />
          </div>
        </FocusTrap>
      )}
    </div>
  );
}