import React, { useState } from 'react';
import Modal, { ModalSizes } from "../Modal";

interface AddStateModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onAddState: (stateName: string) => void;
}



const AddStateModal: React.FC<AddStateModalProps> = ({ isOpen, onRequestClose, onAddState }) => {
  const [stateName, setStateName] = useState<string>('');

  const handleAddState = () => {
    onAddState(stateName);
    setStateName('');
    onRequestClose();
  };

  const customStyles = {
    overlay: {
      zIndex: 1000, // Ensure the modal appears above other elements
    },
  };

  return (
    <Modal isOpen={isOpen} closeModal={onRequestClose} size={ModalSizes.small} height="auto">
    
      <h2>Add New State</h2>
      <input
        type="text"
        value={stateName}
        onChange={(e) => setStateName(e.target.value)}
        placeholder="Enter state name"
      />
      <button onClick={handleAddState}>Add State</button>
      <button onClick={onRequestClose}>Cancel</button>
    </Modal>
  );
};

export default AddStateModal;
