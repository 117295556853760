import React, { useMemo, useState, useEffect, FC } from 'react';
import { Col, Row } from 'reactstrap';
import '../../scss/Time Off/TimeOffRequestRow.scss';
import Chip from '@mui/material/Chip';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { HiDocument } from 'react-icons/hi';
import intl from 'react-intl-universal';
import { confirmAlert } from 'react-confirm-alert';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { useToastMessageQueue } from '../../components/ToastMessages/ToastMessageProvider';
import { handleAPIError } from 'common/errorHandler';

const TimeOffRequestRow = ({ data }) => {

    const [open, setOpen] = useState(false);

    const [openReportsMenu, setOpenReportsMenu] = useState(false);

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const [errors, setErrors] = useState({});

    const workflowStateName = useMemo(() => {
        return data.workflowStateName
    }, [data.workflowStateName])

    const color = workflowStateName === intl.get('requestStatusSelect.pending') ? "warning" : workflowStateName === intl.get('requestStatusSelect.approved') ? "success" : workflowStateName === intl.get('requestStatusSelect.observed') ? "default" : workflowStateName === intl.get('requestStatusSelect.open') ? "primary" : workflowStateName === intl.get('requestStatusSelect.reOpen') ? "secondary" : "error";

    const handleCloseMenu = () => {
        setOpen(false);
        setOpenReportsMenu(false);
    };

    const handleDocumentClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        const isMenuButton =
            target.closest('#TimeOffDropdown')
        if (isMenuButton) {
            return;
        }
        handleCloseMenu();
    };

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    const handleDelete = () => {
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('delete.modal.timeOffRequestRow.message'),
            buttons: [
                {
                    label: intl.get('delete.modal.cancel.button'),
                    onClick: () => { }
                },
                {
                    label: intl.get('delete.modal.delete.button'),
                    onClick: async () => {
                        const response = await api.timeOffApi.apiVversionTimeOffIdDelete(data.id, "1", {}).then((response) => {
                            console.log('address deleted: ' + response);
                            toast.success({ body: intl.get('timeOffRequestRow.toast.success.handleDelete')});
                            handleCloseMenu();
                        }).catch((error) => {
                            handleAPIError(error, toast, errors);
                            setErrors({ ...errors });
                        });
                    },
                }
            ]
        });
    };

    return (
        <div className="container-fluid my-3 p-3 requestRow">
            <Row md={12}>
                    <Col md={8}>
                        <h5 className="d-inline-block mb-0">{data.timeOffRequestTypeName}</h5>
                        <Chip
                            color={color} className={color === "default" ? 'observed' : ''}
                            style={{marginBottom: "4px", marginLeft: "10px"}}
                            label={data.workflowStateName}
                        />
                    </Col>
                    <Col md={4} className="text-end">
                    <a title={"test"} className='td-icon' role="button" id="TimeOffDropdown" onClick={() => {setOpen(true), setOpenReportsMenu(true)}} >
                        <BsThreeDotsVertical/>
                    </a>
                    <div className={open ? "dropdown-menu dark timeOff-menu" : "dropdown-menu timeOff-menu"} aria-labelledby="TimeOffDropdown"  style={openReportsMenu?{"display":"block"}:{"display":"none"}} >
                        {workflowStateName === "Pending" || workflowStateName === "Pendiente" ?
                            <a className="dropdown-item" onClick={handleCloseMenu}>{intl.get('timeOff.cancel')}</a>
                        :
                            <>
                                <a className="dropdown-item" onClick={handleDelete}>{intl.get('timeOff.delete')}</a>
                                <a className="dropdown-item" onClick={handleCloseMenu}>{intl.get('timeOff.edit')}</a>
                            </>
                        }
                    </div>
                    </Col>
            </Row>
            <Row md={12}>
                <Col md={12}>
                    {/*TODO: remove "vacations" flag for actual attachment information*/}
                    {/* <p className="mt-2 mb-0">{data.from} - {data.to}{data.timeOffRequestTypeName === "Vacations" ? <><HiDocument style={{marginLeft: "10px"}}/> {intl.get('timeOff.attachments')}</> : ""}</p> */}
                    <p className="mt-2 mb-0">{data.from} - {data.to}{data.timeOffRequestTypeName ? <><HiDocument style={{marginLeft: "10px"}}/> {intl.get('timeOff.attachments')}</> : ""}</p>
                </Col>
            </Row>
        </div>
    )
}

export default TimeOffRequestRow;