import React, { useEffect } from 'react';

const ProjectCompactPagination = ({ handlePageClick, limit, projectsByClient, nextPage, prevPage, input, pageCount, setpageCount, initialPage, finalPage, idClient }) => {

    useEffect(() => {
        setpageCount(Math.ceil(projectsByClient?.length / limit));
    }, [projectsByClient, limit]);

    const pageNumbers: number[] = [];

    for (let i = 1; i <= pageCount; i++) {
        pageNumbers.push(i);
    };

    return (
        <nav className='pagination' aria-label='...'>
            <ul className='pagination justify-content-center mb-0'>
                <li className={input.currentPage === 1 ? 'page-item disabled' : 'page-item'}>
                    <a className='page-link' href={`/client/detail/${idClient}#project`} aria-label="Initial" onClick={() => initialPage()}>
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                </li>
                <li className={input.currentPage === 1 ? 'page-item disabled' : 'page-item'}>
                    <a className='page-link' href={`/client/detail/${idClient}#project`} aria-label="Previous" onClick={() => prevPage()}>
                        <span aria-hidden="true">&lt;</span>
                    </a>
                </li>
                {pageNumbers.map(num => (
                    <li className={input.currentPage === num ? 'page-item active' : 'page-item'} key={num}>
                        <a className='page-link' href={`/client/detail/${idClient}#project`} onClick={() => handlePageClick(num)}>{num}</a>
                    </li> 
                ))}
                <li className={input.currentPage === pageCount ? 'page-item disabled' : 'page-item'}>
                    <a className='page-link' href={`/client/detail/${idClient}#project`} aria-label="Next" onClick={() => nextPage()}>
                        <span aria-hidden="true">&gt;</span>
                    </a>
                </li>
                <li className={input.currentPage === pageCount ? 'page-item disabled' : 'page-item'}>
                    <a className='page-link' href={`/client/detail/${idClient}#project`} aria-label="Final" onClick={() => finalPage()}>
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default ProjectCompactPagination;