import React, { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Project, Task } from 'axiosApi/models';

const TimesheetAutocomplete = ({ prefixKey, value, setValue, options, hasProjectValue, placeholder, className, name, type, disabled = false, loading = true }) => {
  const formatProjectInputValue = (project: Project) => {
    return project ? (project.client.name + ' - ' + project.name) : '';
  }
  const formatTaskInputValue = (task: Task) => {
    return task ? (task.job.description + ' - ' + task.description) : '';
  }
  const selectedOption = options ? options.find(o => o.id == value) : undefined;

  const ref = useRef(null);

  const onProjectChange = (e, project) => {
    onTaskChange(null, null); // Changing project should reset task.
    if (project) {
      setInputValue(formatProjectInputValue(project));
      setValue(project.id);
    }
    else {
      setValue(null);
      setInputValue('');
    }

  }
  const filterProjectOptions = (options, state) => {
    return (options as Project[]).filter(project => {
      return project.client.name.toLowerCase().includes(state.inputValue.toLowerCase()) ||
        project.name.toLowerCase().includes(state.inputValue.toLowerCase()) ||
        formatProjectInputValue(project).toLowerCase().includes(state.inputValue.toLowerCase()) ||
        state.inputValue === '';
    });
  };

  const filterTaskOptions = (options, state) => {
    return (options as Task[]).filter(task => {
      return task.job.description.toLowerCase().includes(state.inputValue.toLowerCase()) ||
        task.description.toLowerCase().includes(state.inputValue.toLowerCase()) ||
        formatTaskInputValue(task).toLowerCase().includes(state.inputValue.toLowerCase()) ||
        state.inputValue === '';
    });
  };

  const onTaskChange = (e, task) => {
    if (task) {
      setInputValue(formatTaskInputValue(task));
      setValue(task);
    }
    else {
      setValue(null);
      setInputValue('')
    }

  }
  const formatedInputValue = selectedOption ? (type == "project" ? (formatProjectInputValue(selectedOption)) : formatTaskInputValue(selectedOption)) : '';

  const [inputValue, setInputValue] = useState<string>(formatedInputValue);

  useEffect(() => {
    if (hasProjectValue && options && options.length == 1 && !!!value) {
      setValue(options[0]);
    }

  }, [options]);

  if (inputValue != formatedInputValue) {
    setInputValue(formatedInputValue);
  }

  return type == "project" ?
    <Autocomplete
      disabled={disabled}
      ref={ref}
      className={className}
      loading={loading}
      disablePortal
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      forcePopupIcon={false}
      id={prefixKey}
      value={selectedOption || null}
      inputValue={inputValue}
      onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
      isOptionEqualToValue={(project, option) => project.id === option.id}
      filterOptions={filterProjectOptions}
      options={options ? options.sort((a, b) => -(b.client.name + b.name).localeCompare(a.client.name + a.name)) : []}
      groupBy={(project) => project.client.name}
      getOptionLabel={(project) => project ? formatProjectInputValue(project) : ''}
      onChange={onProjectChange}
      renderInput={(params) => <TextField {...params} placeholder={placeholder} name={name} />}
      renderOption={(props, option) => (<li {...props} key={option.id.toString()}> {option.name} </li>)}
      noOptionsText={<>{(options && options.length) ? "No Options" : (loading ? "Loading" : "Select a project first.")}</>}
    /> :
    <Autocomplete
      ref={ref}
      loading={loading}
      disabled={disabled}
      className={className}
      disablePortal
      clearOnBlur
      handleHomeEndKeys
      forcePopupIcon={false}
      id={prefixKey}
      value={selectedOption || null}
      inputValue={inputValue}
      onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
      isOptionEqualToValue={(task, value) => task.id === value.id}
      options={options ? options.sort((a, b) => -(b.job.description + b.description).localeCompare(a.job.description + a.description)) : []}
      groupBy={(task) => task.job.description}
      getOptionLabel={(task) => task ? formatTaskInputValue(task) : ''}
      filterOptions={filterTaskOptions}
      onChange={onTaskChange}
      renderInput={(params) => <TextField {...params} placeholder={ loading ? "Loading" : placeholder} name={name} />}
      renderOption={(props, option) => (<li {...props} key={option.id.toString()}> {option.description} </li>)}
      noOptionsText={<>{(options && options.length) ? "No Options" : (loading ? "Loading" : "Select a project first.")}</>}
    />
    ;
}

export default TimesheetAutocomplete;