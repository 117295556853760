import React, { useState, useEffect } from 'react';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import 'scss/Reports/ReportsComponent.scss';
import './ReportsFilterComponent';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { ReportsFilterComponent, ReportsFilterComponentProps } from './ReportsFilterComponent';
import { TimeSheetDetailedReport, UserEntityDetails } from '../../axiosApi/models';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { HiChevronUp, HiChevronDown, HiOutlineCash } from 'react-icons/hi'
import { SCOPES } from '../../common/permissions';
import { PermissionsGate } from '../PermissionsGate';
import { styled } from '@mui/material/styles';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Colors } from 'chart.js';
import { getUserEntity } from 'common/UserEntityProvider';
import intl from 'react-intl-universal';
import {ENTITY_TYPE_CLIENT, PIECOLORS} from '../../common/constants';
import { handleAPIError } from '../../common/errorHandler';

interface Task {
    idTask: number;
    taskDescription: string;
    time: number;
    idEmployee: number;
    employeeName: string;
    date: Date
}

interface Job {
    idJob: number;
    jobDescription: string;
    tasks: Task[];
    employees: Employee[];
    billableHours: number;
    totalHours: number;
    nonBillableHours: number;
    billingAmount: number;
}

interface Client {
    idClient: number;
    clientName: string;
    projects: Project[];
    totalHours: number;
    billableHours: number;
    nonBillableHours: number;
    billingAmount: number;
}

interface Project {
    idProject: number;
    projectName: string;
    jobs: Job[];
    totalHours: number;
    billableHours: number;
    nonBillableHours: number;
    employees: Employee[];
    billingAmount: number;
}

interface Employee {
    idEmployee: number;
    employeeName: string;
    totalHours: number;
    billableHours: number;
    nonBillableHours: number;
    monthlyRate: number;
    hourlyRate: number;
    billingAmount: number;
}

export const ClientBillingReportComponent = () => {

    const api: Api = useApi();
    const toast = useToastMessageQueue();
    const [results, setResults] = React.useState<TimeSheetDetailedReport[]>([]);
    const [clients, setClients] = React.useState<Client[]>([]);
    const [pieData, setPieData] = React.useState<any>(null);
    const [filterData, setFilterData] = React.useState<any>(null);
    const [errors, setErrors] = useState({});
    const userEntity : UserEntityDetails = getUserEntity();
    const handleFilterChange = (filterData) => {
        fetchData(filterData);
        setFilterData(filterData);
    }
    ChartJS.register(ArcElement, Tooltip, Legend, Colors);
    
    useEffect(() => {
        // Actualiza el título del documento usando la API del navegador
        const clientJobTaskTree = createClientProjectJobTaskTree(results);
        setClients(clientJobTaskTree);
    }, [results]);


    const fetchData = async (filterData: ReportsFilterComponentProps) => {
        const response = await api.billingReportsApi.apiVversionBillingDetailedReportClientGet("1", filterData.from, filterData.to, filterData.projects, userEntity.entityId, filterData.employees, filterData.jobTypes, {}).then((response) => {
            if (response.data.data) {
                setResults(response.data?.data);
            };
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({...errors});
        });
    };

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));



    function createClientProjectJobTaskTree(timesheetData: TimeSheetDetailedReport[]): Client[] {
        const clients: Client[] = [];
        setPieData(null);
        for (const entry of timesheetData) {
            let client = clients.find((c) => c.idClient === entry.idClient);

            if (!client) {
                client = {
                    idClient: entry.idClient,
                    clientName: entry.clientName,
                    projects: [],
                    billableHours: entry.billable ? entry.time : 0,
                    nonBillableHours: entry.billable ? 0 : entry.time,
                    totalHours: entry.time,
                    billingAmount: !entry.billable ? 0 : (entry.hourlyRate > 0 ? entry.time / 60 * entry.hourlyRate : 0)
                };
                clients.push(client);
            }
            else {
                client.billableHours += entry.billable ? entry.time : 0;
                client.nonBillableHours += entry.billable ? 0 : entry.time;
                client.totalHours += entry.time;
                client.billingAmount += !entry.billable ? 0 : (entry.hourlyRate > 0 ? entry.time / 60 * entry.hourlyRate : 0);
            }


            let project = client.projects.find((p) => p.idProject === entry.idProject);

            if (!project) {
                project = {
                    idProject: entry.idProject,
                    projectName: entry.projectName,
                    jobs: [],
                    billableHours: entry.billable ? entry.time : 0,
                    nonBillableHours: entry.billable ? 0 : entry.time,
                    totalHours: entry.time,
                    employees: [],
                    billingAmount: !entry.billable ? 0 : (entry.hourlyRate > 0 ? entry.time / 60 * entry.hourlyRate : 0)

                };
                client.projects.push(project);
            }
            else {
                project.billableHours += entry.billable ? entry.time : 0;
                project.nonBillableHours += entry.billable ? 0 : entry.time;
                project.totalHours += entry.time;
                project.billingAmount += !entry.billable ? 0 : (entry.hourlyRate > 0 ? entry.time / 60 * entry.hourlyRate : 0);
            }

            let job = project.jobs.find((j) => j.idJob === entry.idJob);

            if (!job) {
                job = {
                    idJob: entry.idJob,
                    jobDescription: entry.jobDescription,
                    tasks: [],
                    billableHours: entry.billable ? entry.time : 0,
                    nonBillableHours: !entry.billable ? entry.time : 0,
                    totalHours: entry.time,
                    billingAmount: !entry.billable ? 0 : (entry.hourlyRate > 0 ? entry.time / 60 * entry.hourlyRate : 0),
                    employees: []
                };
                project.jobs.push(job);
            }
            else {
                job.billableHours += entry.billable ? entry.time : 0;
                job.nonBillableHours += entry.billable ? 0 : entry.time;
                job.totalHours += entry.time;
                job.billingAmount += !entry.billable ? 0 : (entry.hourlyRate > 0 ? entry.time / 60 * entry.hourlyRate : 0);
            }


            const task: Task = {
                idTask: entry.idTimesheet,
                taskDescription: entry.taskDescription,
                time: entry.time,
                idEmployee: entry.idEmployee,
                employeeName: entry.employeeName,
                date: entry.date
            };
            job.tasks.push(task);

            let employee = job.employees.find((j) => j.idEmployee === entry.idEmployee);
            if (!employee) {
                employee = {
                    idEmployee: entry.idEmployee,
                    employeeName: entry.employeeName,
                    billableHours: entry.billable ? entry.time : 0,
                    nonBillableHours: entry.billable ? 0 : entry.time,
                    totalHours: entry.time,
                    hourlyRate: entry.hourlyRate,
                    monthlyRate: entry.monthlyRate,
                    billingAmount: !entry.billable ? 0 : (entry.hourlyRate > 0 ? entry.time / 60 * entry.hourlyRate : 0)
                }
                job.employees.push(employee);
            }
            else {
                employee.billableHours += entry.billable ? entry.time : 0;
                employee.nonBillableHours += entry.billable ? 0 : entry.time;
                employee.totalHours += entry.time;
                employee.billingAmount += !entry.billable ? 0 : (entry.hourlyRate > 0 ? (entry.time * entry.hourlyRate) / 60 : 0);
            }

            //Pie Data
            const data = {
                labels: clients.map((c) => c.clientName),
                datasets: [{
                    label: 'Billing Amount',
                    data: clients.map((c) => c.billingAmount),
                    backgroundColor: PIECOLORS,
                    borderWidth: 0, 
                    hoverOffset: 6
                }]

            };

            setPieData(data);

        }



        return clients;
    }

    function JobRow(props: { jobRow: Job, index: number }) {
        const { jobRow, index } = props;
        const [open2, setOpen2] = React.useState(false);
        return (<>
            <TableRow key={jobRow.idJob} className={(index % 2 == 0) ? "jobRowOdd" : "jobRow"} >
                <TableCell></TableCell>
                <TableCell><IconButton aria-label="expand row" size="small" onClick={() => setOpen2(!open2)}>
                    {open2 ? <HiChevronUp /> : <HiChevronDown />}</IconButton>{jobRow.jobDescription}</TableCell>
                <TableCell></TableCell>
                <TableCell align="right">{(jobRow.totalHours / 60).toFixed(2)}</TableCell>
                <TableCell align="right">{(jobRow.billableHours / 60).toFixed(2)}</TableCell>
                <TableCell align="right">{(jobRow.nonBillableHours / 60).toFixed(2)}</TableCell>
                <TableCell align="right">${(jobRow.billingAmount).toFixed(2)}</TableCell>
            </TableRow>
            {open2 && jobRow.employees.map((employee, index) => (<EmployeeRow key={employee.idEmployee} employee={employee} index={index}></EmployeeRow>))
            }
        </>);

    }

    function ProjectRow(props: { row: Project, index: number }) {
        const { row, index } = props;
        const [open, setOpen] = React.useState(false);

        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} className={(index % 2 == 0) ? "projectRowOdd" : "projectRow"}>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <HiChevronUp /> : <HiChevronDown />}
                        </IconButton>
                        <b>{row.projectName}</b>
                    </TableCell>
                    <TableCell component="th" scope="row" >
                    </TableCell>
                    <TableCell component="th" scope="row" >
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                        {(row.totalHours / 60).toFixed(2)}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                        {(row.billableHours / 60).toFixed(2)}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                        {(row.nonBillableHours / 60).toFixed(2)}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                        $ {(row.billingAmount).toFixed(2)}
                    </TableCell>
                </TableRow>

                {open &&
                    row.jobs.map((jobRow, index) => (
                        <JobRow key={jobRow.idJob} jobRow={jobRow} index={index}></JobRow>
                    ))

                }

            </React.Fragment>
        );
    }

    function ClientRow(props: { client: Client }) {

        const { client } = props;

        return (
            <div className="card mt-4">
                <div className="container card-header">
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell> <h4>{client.clientName}</h4></TableCell>
                                <TableCell align="right">$ {(client.billingAmount).toFixed(2)}</TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </div>
                <div className="container card-body">
                    <div>
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>{intl.get('billingReportComponent.clientRow.tableCel.project')}</b></TableCell>
                                        <TableCell><b>{intl.get('billingReportComponent.clientRow.tableCel.job')}</b></TableCell>
                                        <TableCell><b>{intl.get('billingReportComponent.clientRow.tableCel.employee')}</b></TableCell>
                                        <TableCell align="right"><b>{intl.get('billingReportComponent.clientRow.tableCel.totalHours')}</b></TableCell>
                                        <TableCell align="right"><b>{intl.get('billingReportComponent.clientRow.tableCel.billable')}</b></TableCell>
                                        <TableCell align="right"><b>{intl.get('billingReportComponent.clientRow.tableCel.nonBillable')}</b></TableCell>
                                        <TableCell align="right"><b>{intl.get('billingReportComponent.clientRow.tableCel.billingAmoun')}</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {client.projects.map((row, index) => (
                                        <ProjectRow key={row.idProject} row={row} index={index} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        )
    }

    function EmployeeRow(props: { employee: Employee, index: number }) {
        const { employee, index } = props;
        return (<TableRow key={employee.idEmployee} className={(index % 2 == 0) ? "employeeRowOdd" : "employeeRow"} >
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell component="th" scope="row">
                {employee.employeeName}
            </TableCell>
            <TableCell align="right">
                {(employee.totalHours / 60).toFixed(2)}
            </TableCell>
            <TableCell align="right">
                {(employee.billableHours / 60).toFixed(2)}
            </TableCell>
            <TableCell align="right">
                {(employee.nonBillableHours / 60).toFixed(2)}
            </TableCell>
            <TableCell align="right">${(employee.billingAmount).toFixed(2)}</TableCell>

        </TableRow>);
    }

    return (

        <div className='container'>
            <div className='card mt-4'>
                <div className='container card-header'>
                    <h2 className="title"><HiOutlineCash className='mb-1' /> {intl.get('billingReportComponent.header')}</h2>
                </div>
                <div className='container card-body'>
                    <ReportsFilterComponent handleFilterChange={handleFilterChange} errors={errors} setErrors={setErrors} ></ReportsFilterComponent>
                    <PermissionsGate viewScopes={[SCOPES['reports.billing.read']]} editScopes={[SCOPES['reports.billing.read']]} entityType={ENTITY_TYPE_CLIENT} viewRoles={[]} editRoles={[]} RenderError={() => (<span>{intl.get('permissionsGate')}</span>)} >
                        {
                            clients && clients.map((client, i) =>
                                <ClientRow key={client.idClient} client={client}></ClientRow>
                            )
                        }
                    </PermissionsGate>
                    
                </div>
            </div>
        </div>
    )
}




