import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import ProjectForm from "./ProjectForm";
import EmployeeProject from "./EmployeeProject";
import JobProject from "./JobProject";
import "scss/ProjectDetail.scss";
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { Client, ClientContact, CreateProjectCommand, UpdateProjectCommand } from 'axiosApi/models';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { ButtonWithOptions, ButtonWithOptionsType } from "components/common/ButtonWithOptions";
import { ROLES, SCOPES } from "common/permissions";
import { PermissionsGate } from "components/PermissionsGate";
import { HiArrowLeft, HiOutlineSave, HiPencilAlt } from "react-icons/hi";
import { ProjectBudgetStatusReport } from "components/Reports/ProjectBudgetStatusReport";
import { HoursByEmployeeReportComponent } from "components/Reports/HoursByEmployeeReportComponent";
import intl from 'react-intl-universal';
import Spinner from '../Spinner';

export type ProjectDetailInput = {
    name?: string | null,
    idClient?: number | null,
    client?: {
        name: string | null,
        tradeName: string | null,
        clientContact: ClientContact | any,
    },
    jiraUser?: string | null,
    jiraToken?: string | null,
    jiraProjectKey?: string | null,
    jiraUrl?: string | null,
    budget?: number | null,
    currentPage?: number
};

const ProjectDetail = () => {

    const [startDate, setStartDate] = useState(new Date(Date.parse("2020-01-01")));
    const [endDate, setEndDate] = useState(new Date(Date.now()));
    
    const [input, setInput] = useState<ProjectDetailInput | null>({
        name: '',
        idClient: null,
        client: {
            name: '',
            tradeName: '',
            clientContact: { client: { name: '' } },
        },
        jiraUser: '',
        jiraToken: '',
        jiraProjectKey: '',
        jiraUrl: '',
        budget: 0,
        currentPage: 1
    });

    const api: Api = useApi();

    const { idProject } = useParams();
    const idProjectNumber = parseInt(idProject);
    const [filterData, setFilterData] = useState({ from:startDate, projects:idProject, clients:"", employees:"", jobTypes:"",  to: endDate });
    const [clients, setClients] = useState<Client[]>(null);

    const [errors, setErrors] = useState({});

    const [disabled, setDisabled] = useState(false);

    const [disabledNew, setDisabledNew] = useState(false);

    const navigate = useNavigate();

    const toast = useToastMessageQueue();

    const [loading, setLoading] = useState<boolean>(false);

    const fetchProjectDetail = async (idProjectNumber: number) => {
        const response = await api.projectApi.apiVversionProjectIdGet(idProjectNumber, "1", {}).then((response) => {
            if (response.data) {
                setInput({
                    name: response.data?.data?.project?.name,
                    idClient: response.data?.data?.project?.idClient,
                    client: {
                        name: response.data?.data?.project?.client?.name,
                        tradeName: response.data?.data?.project?.client?.tradeName,
                        clientContact: { client: { name: response.data?.data?.project?.client?.clientContact } }
                    },
                    jiraUser: response.data?.data?.project?.jiraUser,
                    jiraToken: response.data?.data?.project?.jiraToken,
                    jiraProjectKey: response.data?.data?.project?.jiraProjectKey,
                    jiraUrl: response.data?.data?.project?.jiraUrl,
                    budget: response.data?.data?.project?.budget
                });
            };
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('projectDetail.toast.error.fetchProjectDetail'), body: error.message });
        });
    };

    const fetchClients = async () => {
        const response = await api.clientApi.apiVversionClientAllGet("1", 1, 100, {}).then((response) => {
            if (response.data.data) {
                setClients(response.data.data.queryResult);
            };
        }).catch((error) => {
            if (error.response) {
                console.log("Data :", error.response.data);
                console.log("Status :" + error.response.status);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log("Error", error.message);
            }
            toast.error({ header: intl.get('projectDetail.toast.error.fetchClients'), body: error.message });
        });
    };

    useEffect(() => {
        fetchClients();
        if (idProjectNumber) {
            fetchProjectDetail(idProjectNumber);
            setDisabled(true);
        };
        setDisabledNew(true);
    }, [idProjectNumber]);

    const handleInputChange = function (e) {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
        setErrors(validate({
            ...input,
            [e.target.name]: e.target.value
        }));
    };

    const handleClientChange = (e) => {
        const inputVal = {
            ...input,
            idClient: e.id,
            client: {
                name: e.name,
                tradeName: e.tradeName,
                clientContact: { client: { name: e.clientContact } },
            }
        };
        setInput({ ...inputVal });
        setErrors(validate({
            ...input,
            idClient: e.id
        }));
    };

    const validate = function (input: ProjectDetailInput) {
        setDisabledNew(false);
        let errors: any = {};
        if (!input.name) {
            errors.name = intl.get('validate.errors.isRequired');
        }
        if (!input.idClient) {
            errors.idClient = intl.get('validate.errors.isRequired');
        }
        if (((input.jiraToken || input.jiraToken !== "") || input.jiraProjectKey || input.jiraUrl) && !input.jiraUser) {
            errors.jiraUser = intl.get('validate.errors.jiraFields');
        }
        if ((input.jiraUser || input.jiraProjectKey || input.jiraUrl) && !input.jiraToken) {
            errors.jiraToken = intl.get('validate.errors.jiraFields');
        }
        if (((input.jiraToken || input.jiraToken !== "") || input.jiraUser || input.jiraUrl) && !input.jiraProjectKey) {
            errors.jiraProjectKey = intl.get('validate.errors.jiraFields');
        }
        if (((input.jiraToken || input.jiraToken !== "") || input.jiraProjectKey || input.jiraUser) && !input.jiraUrl) {
            errors.jiraUrl = intl.get('validate.errors.jiraFields');
        } else if (input.jiraUrl && !/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(input.jiraUrl)) {
            errors.jiraUrl = intl.get('validate.errors.isRequired');
        }
        if (!input.budget && input.budget !== 0) {
            errors.budget = intl.get('validate.errors.budgetMoreEqualZero');
        } else if (input.budget < 0) {
            errors.budget = intl.get('validate.errors.positive');
        }
        return errors;
    };

    const handleSubmit = async (saveAndEdit: boolean = false) => {
        setLoading(true);
        const errors = validate(input);
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            if (idProjectNumber) {
                let cmd: UpdateProjectCommand = {
                    id: idProjectNumber,
                    name: input.name,
                    idClient: input.idClient,
                    jiraUser: input.jiraUser,
                    jiraToken: input.jiraToken,
                    jiraProjectKey: input.jiraProjectKey,
                    jiraUrl: input.jiraUrl,
                    budget: input.budget
                };
                const response = await api.projectApi.apiVversionProjectPut("1", cmd, {}).then((response) => {
                    if (saveAndEdit)
                        setDisabled(true);
                    else
                        navigate('/projects');
                    toast.success({ body: intl.get('projectDetail.toast.success.handleSubmit')});
                    setLoading(false);
                }).catch((error) => {
                    if (error.response) {
                        console.log("Data :", error.response.data);
                        console.log("Status :" + error.response.status);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                    toast.error({ header: intl.get('projectDetail.toast.error.handleSubmit'), body: error.message });
                    setLoading(false);
                });
            } else {
                let cmd: CreateProjectCommand = {
                    name: input.name,
                    idClient: input.idClient,
                    jiraUser: input.jiraUser,
                    jiraToken: input.jiraToken,
                    jiraProjectKey: input.jiraProjectKey,
                    jiraUrl: input.jiraUrl,
                    budget: input.budget
                };
                const response = await api.projectApi.apiVversionProjectPost("1", cmd, {}).then((response) => {
                    toast.success({ body: intl.get('projectDetail.toast.success.handleSubmit.add')});
                    setLoading(false);
                    if (saveAndEdit)
                        navigate(`/project/detail/${response.data.data.id}`);
                    else
                        navigate('/projects');
                }).catch((error) => {
                    if (error.response) {
                        console.log("Data :", error.response.data);
                        console.log("Status :" + error.response.status);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                    toast.error({ header: intl.get('projectDetail.toast.error.handleSubmit.add'), body: error.message });
                    setLoading(false);
                });
            };
        };
    };

    const isJiraReady = () => {
        return input.jiraProjectKey != '' && input.jiraToken != '' && input.jiraUrl != '' && input.jiraUser != '';
    };

    const handleReturn = () => {
        navigate(-1);
    };

    const handleEditMode = (e) => {
        e.preventDefault();
        if (!disabled) {
            setInput({ ...input, jiraToken: input.jiraToken == '**encrypted**' ? '' : input.jiraToken });
        }
        else if (input.jiraToken == '') {
            setInput({ ...input, jiraToken: '**encrypted**' });
        }
        setErrors({});
        if (idProjectNumber) {
            fetchProjectDetail(idProjectNumber);
        }
        setDisabled(!disabled);
    };

    const handleSaveClick = () => {
        handleSubmit(true);
    };

    const handleSaveCloseClick = () => {
        handleSubmit(false);
    };

    return (
        <div className="container">
            <div className="card mt-4">
                <div className="card-header">
                    {
                        idProject ?
                        <div className='d-flex align-items-center'>
                            <h2 className="title">{intl.get('projectDetail.header')}</h2>
                            
                        </div> :
                        <h2 className="title">{intl.get('newProject.header')}</h2>
                    }
                </div>
                <div className="container card card-body">
                    <ProjectForm
                        errors={errors}
                        input={input}
                        handleInputChange={handleInputChange}
                        handleClientChange={handleClientChange}
                        clients={clients}
                        selectedClient={input.idClient}
                        disabled={disabled}
                        idProject={idProject}
                        validate={validate}
                        setErrors={setErrors}
                    />
                    <div className='d-flex justify-content-end my-3'>
                        {
                            !idProject && !disabled ?
                            <>
                                {
                                    loading === true ?
                                    <Spinner small={true} /> :
                                    <>
                                        <button type='button' className='btn btn-outline-dark me-2' id='return' onClick={handleReturn}>
                                            <HiArrowLeft className="mb-1"/>
                                        </button>
                                        <PermissionsGate  viewScopes={[SCOPES['projects.edit']]} editScopes={[SCOPES['projects.edit']]} editRoles={[ROLES.projectReader]} viewRoles={[ROLES.projectReader]}>
                                            <ButtonWithOptions options={[/*{ title: 'Save', onClick: handleSaveClick, disabled: false, type: ButtonWithOptionsType.item }, */{ title: `${intl.get('save&close.button')}`, onClick: handleSaveCloseClick, disabled: Object.keys(errors).length > 0 || disabledNew, type: ButtonWithOptionsType.item }]} outline={true} disabled={Object.keys(errors).length > 0 || disabledNew} className="btn btn-primary" onClick={handleSaveClick} ><HiOutlineSave className="mb-1"/> {intl.get('save.button')}</ButtonWithOptions>
                                        </PermissionsGate>
                                    </>
                                }
                            </> : null
                        }
                        {
                            idProject && disabled ?
                            <>
                                <button type='button' className='btn btn-outline-dark me-2' id='return' onClick={handleReturn}>
                                    <HiArrowLeft className="mb-1"/>
                                </button>
                                <button type='button' className='btn btn-primary' id='edit' onClick={handleEditMode}>   
                                    <HiPencilAlt className='mb-1' /> {intl.get('edit.button')}
                                </button>
                            </> : null
                        }
                        {
                            idProject && !disabled ?
                            <>
                                {
                                    loading === true ?
                                    <Spinner small={true} /> :
                                    <>
                                        <button type='button' className='btn btn-outline-dark me-2' id='edit' onClick={handleEditMode}>   
                                            {intl.get('cancel.button')}
                                        </button>
                                        <PermissionsGate  viewScopes={[SCOPES['projects.edit']]} editScopes={[SCOPES['projects.edit']]} editRoles={[ROLES.projectReader]} viewRoles={[ROLES.projectReader]}>
                                            <ButtonWithOptions options={[/*{ title: 'Save', onClick: handleSaveClick, disabled: false, type: ButtonWithOptionsType.item }, */{ title: `${intl.get('save&close.button')}`, onClick: handleSaveCloseClick, disabled: Object.keys(errors).length > 0 || disabled, type: ButtonWithOptionsType.item }]} outline={true} disabled={Object.keys(errors).length > 0 || disabled} className="btn btn-primary" onClick={handleSaveClick} ><HiOutlineSave className="mb-1"/> {intl.get('save.button')}</ButtonWithOptions>
                                        </PermissionsGate>
                                    </>
                                }
                            </> : null
                        }
                    </div>
                </div>
                {
                    idProject &&
                    <>
                        <div className="card mt-4">
                            <EmployeeProject idProject={idProjectNumber} disabled={disabled} errors={errors} setErrors={setErrors}/>
                        </div>
                        <div className="card mt-4">
                            <JobProject idProject={idProjectNumber} isJiraReady={isJiraReady()} projectName={input.name} clientName={input.client.name} disabled={disabled} errors={errors} setErrors={setErrors}/>
                        </div>
                        <ProjectBudgetStatusReport projectId={idProject}  startDate={startDate} budget={input.budget} errors={errors} setErrors={setErrors}/> 
                        <HoursByEmployeeReportComponent filterData={filterData} errors={errors} setErrors={setErrors}/>
                    </>
                }
            </div>
        </div>
    )
}

export default ProjectDetail;