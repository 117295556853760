import { getUserEntity } from 'common/UserEntityProvider';
import { IEditingModeSetings } from './Workflow/WorkflowActionsBar';

export const hasPermission = ({ scopes }) => {

  const scopesMap = {};
  scopes.forEach((scope) => {
    scopesMap[scope] = true;
  });

  const permissions = getUserEntity()?.scopes.map(x => x.scope);


  return permissions?.some(
    (permission) => scopesMap[permission]
  );
};export const hasRole = ({ userRoles, roles }) => {
  const rolesMap = {};
  roles?.forEach((role) => {
    rolesMap[role] = true;
  });

  return userRoles?.some((role) => rolesMap[role]);
};

// Check if the user is the owner of the entity and enable changes
export const isEditableByOwner = (entityType:number, entityId:number, wfEditSettings: IEditingModeSetings ): boolean => {
    return (getUserEntity().entityType == entityType && getUserEntity().entityId == entityId && wfEditSettings?.editByOwner);
}
