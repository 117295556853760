import React, { useEffect } from 'react';
import { useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import 'scss/Reports/ReportsComponent.scss';
import './ReportsFilterComponent';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { ReportsFilterComponentProps } from './ReportsFilterComponent';
import { TimeSheetDetailedReport } from '../../axiosApi/models';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getUserEntity} from 'common/UserEntityProvider';
import { HiChevronUp, HiChevronDown } from 'react-icons/hi'
import { SCOPES } from '../../common/permissions';
import { PermissionsGate } from '../PermissionsGate';
import { styled } from '@mui/material/styles';
import intl from 'react-intl-universal';
import annotationPlugin from 'chartjs-plugin-annotation';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Colors,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Spinner  from '../Spinner';
import { ENTITY_TYPE_EMPLOYEE } from 'common/constants';
import { convertDateAsUTC2 } from 'common/utils';
import { handleAPIError } from '../../common/errorHandler';

interface Task {
    idTask: number;
    taskDescription: string;
    time: number;
    idEmployee: number;
    employeeName: string;
    date: Date
}

interface Job {
    idJob: number;
    jobDescription: string;
    tasks: Task[];
    billableHours: number;
    totalHours: number;
    nonBillableHours: number;
}

interface Client {
    idClient: number;
    clientName: string;
    projects: Project[];
    totalHours: number;
    billableHours: number;
    nonBillableHours: number;
}

interface Project {
    idProject: number;
    projectName: string;
    jobs: Job[];
    totalHours: number;
    billableHours: number;
    nonBillableHours: number;
}

interface DateEntry {
    date: string;
    billableHours: number;
    nonBillableHours: number;
}

interface Employee {
    idEmployee: number;
    employeeName: string;
    totalHours: number;
    billableHours: number;
    nonBillableHours: number;
    monthlyRate: number;
    hourlyRate: number;
    billingAmount: number;
}

interface TimesheetData {
    data: TimeSheetDetailedReport[];
}

export const MyTimesheetStatusComponent = ({ errors, setErrors}) => {

    const api: Api = useApi();
    const toast = useToastMessageQueue();
    const [results, setResults] = React.useState<TimeSheetDetailedReport[]>([]);
    const [clients, setClients] = React.useState<Client[]>([]);
    const [loading, setLoading] = React.useState<Boolean>(false);
    const [chartData, setChartData] = React.useState<any>(null);
    const [clientChartData, setClientChartData] = React.useState<any>(null);
    const [employeeChartData, setEmployeeChartData] = React.useState<any>(null);
    const idEmployee = getUserEntity()?.entityId;
    const currentDate = new Date(); // Get the current date
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const [options, setOptions] = React.useState({});

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        Colors,
        annotationPlugin
    );
    
    useEffect(() => {
        if(!!idEmployee)
            {
            fetchData({
                from: convertDateAsUTC2(firstDayOfMonth),
                to: convertDateAsUTC2(lastDayOfMonth),
                projects: "",
                clients: "",
                employees: idEmployee.toString(),
                jobTypes: ""
            });
        }
    }, [idEmployee]);

    useEffect(() =>{
        if(results!=null){
            const clientJobTaskTree = createClientProjectJobTaskTree(results);
            setClients(clientJobTaskTree);
        }
    },[results]);

    const fetchData = async (filterData: ReportsFilterComponentProps) => {
        setLoading(true);
        const response = await api.timesheetReportsApi.apiVversionTimeSheetDetailedReportAllGet("1", filterData.from, filterData.to, filterData.projects, filterData.clients, filterData.employees, filterData.jobTypes, {}).then((response) => {
            if (response.data.data) {
                setResults(response.data?.data);
                setLoading(false);
            };
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({...errors});
            setLoading(false);
        });
    };

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    function createClientProjectJobTaskTree(timesheetData: TimeSheetDetailedReport[]): Client[] {
        const clients: Client[] = [];
        const dates: DateEntry[] = [];
        const employees: Employee[] = [];
        
        for (const entry of timesheetData) {
            let client = clients.find((c) => c.idClient === entry.idClient);

            if (!client) {
                client = {
                    idClient: entry.idClient,
                    clientName: entry.clientName,
                    projects: [],
                    billableHours: entry.billable ? entry.time : 0,
                    nonBillableHours: entry.billable ? 0 : entry.time,
                    totalHours: entry.time
                };
                clients.push(client);
            }
            else {
                client.billableHours += entry.billable ? entry.time : 0;
                client.nonBillableHours += entry.billable ? 0 : entry.time;
                client.totalHours += entry.time;
            }


            let project = client.projects.find((p) => p.idProject === entry.idProject);

            if (!project) {
                project = {
                    idProject: entry.idProject,
                    projectName: entry.projectName,
                    jobs: [],
                    billableHours: entry.billable ? entry.time : 0,
                    nonBillableHours: entry.billable ? 0 : entry.time,
                    totalHours: entry.time

                };
                client.projects.push(project);
            }
            else {
                project.billableHours += entry.billable ? entry.time : 0;
                project.nonBillableHours += entry.billable ? 0 : entry.time;
                project.totalHours += entry.time;
            }

            let job = project.jobs.find((j) => j.idJob === entry.idJob);

            if (!job) {
                job = {
                    idJob: entry.idJob,
                    jobDescription: entry.jobDescription,
                    tasks: [],
                    billableHours: entry.billable ? entry.time : 0,
                    nonBillableHours: !entry.billable ? entry.time : 0,
                    totalHours: entry.time
                };
                project.jobs.push(job);
            }
            else {
                job.billableHours += entry.billable ? entry.time : 0;
                job.nonBillableHours += entry.billable ? 0 : entry.time;
                job.totalHours += entry.time;
            }

            const task: Task = {
                idTask: entry.idTimesheet,
                taskDescription: entry.taskDescription,
                time: entry.time,
                idEmployee: entry.idEmployee,
                employeeName: entry.employeeName,
                date: entry.date
            };
            job.tasks.push(task);

            let date = dates.find((d) => d.date === new Date(entry.date).toLocaleDateString());

            if (!date) {
                date = {
                    date: new Date(entry.date).toLocaleDateString(),
                    billableHours: entry.billable ? entry.time : 0,
                    nonBillableHours: !entry.billable ? entry.time : 0
                };
                dates.push(date);
            }
            else {
                date.billableHours += entry.billable ? entry.time : 0;
                date.nonBillableHours += entry.billable ? 0 : entry.time;
            }

            let employee = employees.find((j) => j.idEmployee === entry.idEmployee);
            if (!employee) {
                employee = {
                    idEmployee: entry.idEmployee,
                    employeeName: entry.employeeName,
                    billableHours: entry.billable ? entry.time : 0,
                    nonBillableHours: entry.billable ? 0 : entry.time,
                    totalHours: entry.time,
                    hourlyRate: entry.hourlyRate,
                    monthlyRate: entry.monthlyRate,
                    billingAmount: !entry.billable ? 0 : (entry.hourlyRate > 0 ? entry.time / 60 * entry.hourlyRate : 0)
                }
                employees.push(employee);
            }
            else {
                employee.billableHours += entry.billable ? entry.time : 0;
                employee.nonBillableHours += entry.billable ? 0 : entry.time;
                employee.totalHours += entry.time;
                employee.billingAmount += !entry.billable ? 0 : (entry.hourlyRate > 0 ? (entry.time * entry.hourlyRate) / 60 : 0);
            }

        }

        const billable = dates.length>0?dates.map(x=>x.billableHours):[];
        const nonBillable = dates.length>0?dates.map(x=>x.nonBillableHours + x.billableHours):[];
        const averageBillable = dates.length>0?(billable.reduce((a,c)=> a+c)/dates.length)/60:0;
        const averageNonBillable = dates.length>0?(nonBillable.reduce((a,c)=> a+c)/dates.length)/60:0;

        //fill missing dates
        let currentDate = firstDayOfMonth;

        while (currentDate <= lastDayOfMonth) {
            let aDate = dates.find((d) => d.date === currentDate.toLocaleDateString());
            if (!aDate) {
                aDate = {
                    date: currentDate.toLocaleDateString(),
                    billableHours:  0,
                    nonBillableHours:  0
                };
                dates.push(aDate);
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }

        const sortedDates = dates.sort((a,b)=>{
            if(new Date(a.date)>new Date(b.date))
                return 1;
            if(new Date(a.date)<new Date(b.date))
                return -1;
            return 0;
        });

        //Average line
        const annotation = {
            type: 'line',
            color: 'cyan',
            borderColor: 'cyan',
            borderDash: [6, 6],
            borderDashOffset: 0,
            borderWidth: 2,
            label: {
                enabled: true,
                content: (ctx) => "Average Billable: " + averageBillable.toFixed(2),
                position: 'end',
                color: 'black',
                display: true,
                backgroundColor: 'transparent',
                
            },
            scaleID: 'y',
            value: (ctx) => averageBillable
        };

        const annotation2 = {
            type: 'line',
            borderColor: 'black',
            borderDash: [6, 6],
            borderDashOffset: 0,
            borderWidth: 2,
            label: {
                enabled: true,
                content: (ctx) => "Average Total: " + averageNonBillable.toFixed(2),
                position: 'end'
            },
            scaleID: 'y',
            value: (ctx) => averageNonBillable
        };

       setOptions({
            plugins: {
                title: {
                    display: false,
                    text: 'Billable vs Non Billable',
                },
                annotation: {
                    annotations: {
                        annotation,
                        //annotation2
                    }
                }
            },
            responsive: true,
            scales: {
                x: {
                    stacked: true,
        
                    grid: {
                        lineWidth: 0
                    }
                },
                y: {
                    stacked: true,
                },
            }
        });

        //Bar Data
        const data = {
            labels: sortedDates.map((c) => c.date.toLocaleString()),
            datasets: [{
                label: 'Billable',
                data: dates.map((c) => c.billableHours / 60),

                borderWidth: 0,
                hoverOffset: 6
            },
            {
                label: 'Non Billable',
                data: dates.map((c) => c.nonBillableHours / 60),

                borderWidth: 0,
                hoverOffset: 6
            }]
        };

        setChartData(data);

        const sortedClients = clients.sort((a,b)=>{
            if(a.billableHours>b.billableHours)
                return -1;
            if(a.billableHours<b.billableHours)
                return 1;
            return 0;
        });

        const data2 = {
            labels: sortedClients.map((c) => c.clientName),
            datasets: [
                {
                    label: 'Billable',
                    data: sortedClients.map((c) => c.billableHours / 60),
                    borderWidth: 0,
                    hoverOffset: 6
                },
                {
                    label: 'Non Billable',
                    data: sortedClients.map((c) => c.nonBillableHours / 60),
                    borderWidth: 0,
                    hoverOffset: 6
                }
            ]
        };
        setClientChartData(data2);

        const sortedEmployees = employees.sort((a,b)=>{
            if(a.totalHours>b.totalHours)
                return -1;
            if(a.totalHours<b.totalHours)
                return 1;
            return 0;
            
        });

        const data3 = {
            labels: sortedEmployees.map((c) => c.employeeName),
            datasets: [
                {
                    label: 'Billable',
                    data: sortedEmployees.map((c) => c.billableHours / 60),
                    borderWidth: 0,
                    hoverOffset: 6
                },
                {
                    label: 'Non Billable',
                    data: sortedEmployees.map((c) => c.nonBillableHours / 60),
                    borderWidth: 0,
                    hoverOffset: 6
                }
            ]
        };
        setEmployeeChartData(data3);

        return clients;
    }

    function JobRow(props: { jobRow: Job }) {
        const { jobRow } = props;
        const [open2, setOpen2] = React.useState(false);
        return (<><TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell><IconButton aria-label="expand row" size="small" onClick={() => setOpen2(!open2)}>
                {open2 ? <HiChevronUp /> : <HiChevronDown />}</IconButton>{jobRow.jobDescription}</TableCell>
            <TableCell align="right">{(jobRow.totalHours / 60).toFixed(2)}</TableCell>
            <TableCell align="right">{(jobRow.billableHours / 60).toFixed(2)}</TableCell>
            <TableCell align="right">{(jobRow.nonBillableHours / 60).toFixed(2)}</TableCell>
        </TableRow>
            <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell colSpan={4} align="right" style={{ paddingRight: 0 }} >
                    <Collapse in={open2} timeout="auto" unmountOnExit>
                        <Table key={jobRow.idJob} size="small" padding="normal">
                            <TableHead>
                                <StyledTableRow>
                                    <TableCell><b>Date</b></TableCell>
                                    <TableCell><b>Employee</b></TableCell>
                                    <TableCell><b>Task Description</b></TableCell>
                                    <TableCell align="right"><b>Hours</b></TableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    jobRow.tasks.map((task) => (
                                        <TaskRow task={task}></TaskRow>))
                                }</TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow></>
        );
    }

    function ProjectRow(props: { row: Project }) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);
        const [open2, setOpen2] = React.useState(false);


        return (
            <React.Fragment>
                <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <HiChevronUp /> : <HiChevronDown />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row" >
                        <b>{row.projectName}</b>
                    </TableCell>
                    <TableCell>

                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                        {(row.totalHours / 60).toFixed(2)}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                        {(row.billableHours / 60).toFixed(2)}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                        {(row.nonBillableHours / 60).toFixed(2)}
                    </TableCell>

                </StyledTableRow>
                {open && row.jobs.map((jobRow) => (
                    <JobRow key={jobRow.idJob}   jobRow={jobRow}></JobRow>
                ))}

            </React.Fragment>
        );
    }

    function ClientRow(props: { client: Client }) {

        const { client } = props;
        return (
            <div className="card mt-4">
                <div className="container card-header">
                    <h4>{client.clientName}</h4>
                </div>
                <div className="container card-body">
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell><b>Project</b></TableCell>
                                    <TableCell><b>Job</b></TableCell>
                                    <TableCell align="right"><b>Total Hours</b></TableCell>
                                    <TableCell align="right"><b>Billable</b></TableCell>
                                    <TableCell align="right"><b>Not Billable</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {client.projects.map((row) => (
                                    <ProjectRow key={row.idProject} row={row} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>)

    }

    function TaskRow(props: { task: Task }) {
        const { task } = props;
        return (<TableRow key={task.idTask}>
            <TableCell>
                {new Date(task.date).toLocaleDateString()} </TableCell>
            <TableCell component="th" scope="row">
                {task.employeeName}
            </TableCell>
            <TableCell>
                {task.taskDescription}
            </TableCell>
            <TableCell align="right">{(task.time / 60).toFixed(2)}</TableCell>
        </TableRow>);
    }
   
    return (
        <div className='card'>
            {/* Only for Employees */}
            <PermissionsGate viewScopes={[SCOPES['timesheet.read']]} editScopes={[SCOPES['timesheet.edit']]} entityType={ENTITY_TYPE_EMPLOYEE} viewRoles={[]} editRoles={[]} RenderError={() => (<span>{intl.get('permissionsGate')}</span>)} >
                <div className='card-header'>
                    <h4>{intl.get('myTimesheetStatusComponent.title')}</h4>
                </div>
                <div className='card-body'>
                    <div className="col-md-12 col-sm-12">
                        {
                            loading ? <div className=''><Spinner /></div> :
                            chartData && 
                            <Bar options={options} data={chartData} />
                        }
                    </div>
                </div>
            </PermissionsGate> 
        </div>
    )
}

