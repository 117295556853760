import React, { useState, useEffect } from 'react';
import EmployeeProjectForm from './EmployeeProjectForm';
import ProjectEmployeeList from './ProjectEmployeeList';
import Spinner from '../Spinner';
import EmployeeProjectPagination from './EmployeeProjectPagination';
import { useApi } from '../../api/ApiProvider';
import Api from '../../axiosApi/api';
import { CreateEmployeeProjectCommand, Employee, ListOptionDTO, Role, UpdateEmployeeProjectCommand } from 'axiosApi/models';
import { ToastMessageProviderProps, useToastMessageQueue } from 'components/ToastMessages/ToastMessageProvider';
import { confirmAlert } from "react-confirm-alert";
import { SCOPES } from 'common/permissions';
import { PermissionsGate } from 'components/PermissionsGate';
import { convertDateAsUTC } from 'common/utils';
import '../../scss/Project/EmployeeProject.scss';
import intl from 'react-intl-universal';
import {ALLOCATION_TYPES} from '../../common/constants';
import ItemsSelect from '../ItemsSelect';
import { el } from 'date-fns/locale';
import {handleAPIError} from '../../common/errorHandler';
type EmployeeProjectProps = {
    idProject: number,
    disabled: boolean,
    errors: any,
    setErrors: any
};

const EmployeeByProject = ({ idProject, disabled, errors, setErrors }: EmployeeProjectProps) => {

    const DefaultEmployeeProjectInput = {
        idEmployee: null,
        idProject: idProject,
        hourlyRate: 0,
        monthlyRate: 0,
        startDate: null,
        allocationType: 1, 
        allocation: 0, 
        dueDate: null,
        idRole: null,
        currentPage: 1,
        isTimesheetViewer: false,
    };

    const [input, setInput] = useState<CreateEmployeeProjectCommand | UpdateEmployeeProjectCommand | null>(DefaultEmployeeProjectInput);

    const api: Api = useApi();

    const toast = useToastMessageQueue();

    const [employeesByProject, setEmployeesByProject] = useState({
        totalRecords: null,
		employeeProjects: []
    });    

    const [employees, setEmployees] = useState<Employee[]>(null);

    const [roles, setRoles] = useState(null);

    const [loading, setLoading] = useState<boolean>(false);

    const [inactiveEmployees, setInactiveEmployees] = useState(false);

    const [hiddenRole, setHiddenRole] = useState(false);

    const [pageCount, setpageCount] = useState<number>(0);

    const [limit, setLimit] = useState<number>(() => {
		return parseInt(localStorage.getItem('itemsPerPageEmployeeProyect'), 10) || 10;
	});

    useEffect(() => {
		setLimit(parseInt(localStorage.getItem('itemsPerPageEmployeeProyect'), 10) || 10);
	}, []);

    const fetchEmployees = async () => {
        const response = await api.employeeApi.apiVversionEmployeeAllGet("1", 1, 100, {}).then((response) => {
            if (response.data.data) {
                setEmployees(response.data.data.queryResult);
            };
        }).catch((error) => {
            handleAPIError(error, toast, errors);    
            setErrors({ ...errors });            
        });
    };

    const fetchEmployeesByProject = async (idProject: number) => {
        const response = await api.employeeProjectApi.apiVversionEmployeeProjectGetByProjectIdIdGet(idProject, "1", {}).then((response) => {
            if (response.data.data) {
                setEmployeesByProject({
                    totalRecords: response.data?.data?.totalRecords,
                    employeeProjects: response.data?.data?.employeeProjects
                });
            };
            setLoading(false);
        }).catch((error) => {
            handleAPIError(error, toast, errors);    
            setErrors({ ...errors });            
        });
    };

    const fetchRoles = async () => {
        const response = await api.listApi.listApiCallBuilderGet("1", "roles", "PROJECT").then((response) => {
            if (response.data && 'queryResult' in response.data) {
                setRoles(response.data.queryResult);
            } 
            else if (response.data && 'data' in response.data && 'queryResult' in response.data.data) {
                setRoles(response.data.data.queryResult);
            }
        }).catch((error) => {
            handleAPIError(error, toast, errors);
            setErrors({...errors});
            setLoading(false);
        });
    };

    useEffect(() => {
        setLoading(true);
        Promise.all([fetchEmployees(), fetchRoles()]).then(() => { setLoading(false)});
        fetchEmployees();
        fetchRoles();
        if (idProject) {
            fetchEmployeesByProject(idProject);
        };
    }, [idProject, limit]);

    const handlePageClick = pageNum => setInput({ ...input, currentPage: pageNum });

	const nextPage = () => setInput({ ...input, currentPage: input.currentPage + 1 });

	const prevPage = () => setInput({ ...input, currentPage: input.currentPage - 1 });

	const initialPage = () => setInput({ ...input, currentPage: 1 });

	const finalPage = () => setInput({ ...input, currentPage: pageCount });

    const currentDate = new Date();
    
    const currentDateFormatted = currentDate.toISOString();
    const employeesByProjectActive = employeesByProject.employeeProjects?.filter((employee) => employee.dueDate >= currentDateFormatted);
    const employeesByProjectActivePaginated = employeesByProjectActive.slice((input.currentPage - 1) * limit, input.currentPage * limit);
    const employeesByProjectPaginated = employeesByProject.employeeProjects?.slice((input.currentPage - 1) * limit, input.currentPage * limit);

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
        setErrors(validate({
            ...input,
            [e.target.name]: e.target.value
        }));
    };

    const handleEmployeeChange = (e) => {
        const inputVal = {
            ...input,
            employee: { name: e.name, lastName: e.lastName },
            idEmployee: e.id
        };
        setInput({ ...inputVal });
        setErrors(validate({
            ...input,
            employee: { name: e.name, lastName: e.lastName }
        }));
    };

    const handleRoleChange = (e) => {
        const inputVal = {
            ...input,
            idRole: e.id,
            Role: intl.get(e.label)
        };
        setInput({ ...inputVal });
        setErrors(validate({
            ...input,
            idRole: e.id
        }));
    };

    const handleDueDate = (e) => {
        setInput({
            ...input,
            dueDate: e
        });
        setErrors(validate({
            ...input,
            dueDate: e
        }));
    };

    const handleStartDate = (e) => {
        setInput({
            ...input,
            startDate: e
        });
        setErrors(validate({
            ...input,
            startDate: e
        }));
    };

    const handleAllocationTypeChange = (e) => {
        setInput({
            ...input,
            allocationType: e.id
        });
        setErrors(validate({
            ...input,
            allocationType: e.id
        }));
    };

    const isEditMode = () => {
        return Object.keys(input).includes('id');
    };

    const validate = function (input) {
        let errors: any = {};
        
        if (!input.employee) {
            errors.employee = intl.get('validate.errors.isRequired');
        }
        if (!input.hourlyRate && !input.monthlyRate || input.hourlyRate === "") {
            errors.hourlyRate = intl.get('validate.errors.isRequired');
        } else if (input.hourlyRate < 0) {
            errors.hourlyRate = intl.get('validate.errors.positive');
        } 
        if (!input.monthlyRate && !input.hourlyRate || input.monthlyRate === "") {
            errors.monthlyRate = intl.get('validate.errors.isRequired');
        } else if (input.monthlyRate < 0) {
            errors.monthlyRate = intl.get('validate.errors.positive');
        }
        if ((input.hourlyRate && input.hourlyRate > 0 && input.monthlyRate && input.monthlyRate > 0)) {
            errors.hourlyRate = intl.get('validate.errors.selectOnly');
            errors.monthlyRate = intl.get('validate.errors.selectOnly');
        }
        if (input.hourlyRate === "0"  && input.monthlyRate === "0"){
            errors.hourlyRate = intl.get('validate.errors.selectOne');
            errors.monthlyRate = intl.get('validate.errors.selectOne');
        }
        if (!input.idRole) {
            errors.idRole = intl.get('validate.errors.isRequired');
        }
        if (!input.dueDate) {
            errors.dueDate = intl.get('validate.errors.isRequired');
        }
        if (!input.startDate) {
            errors.startDate = intl.get('validate.errors.isRequired');
        }
        if (input.allocation > 100 && input.allocationType == 1 ) {
            errors.allocationType = intl.get('validate.errors.percMore100');
        } else if (input.allocation < 0) {
            errors.allocationType = intl.get('validate.errors.positive');
        }
        if (!input.allocation && input.allocation !== 0) {
            errors.allocation = intl.get('validate.errors.isRequired');
        }
        setErrors({ ...errors });
        console.log('VALIDATE', errors, input)
        return Object.keys(errors).length > 0 ? errors : {};
    };

    const handleEmployeeEdit = (idEmployee: number) => {
        if (!isEditMode()) {
            const editingEmployee = employeesByProject.employeeProjects.find((employee: Employee) => employee.id == idEmployee);
            if (editingEmployee != null) {
                setInput({ ...editingEmployee, id: idEmployee, currentPage: input.currentPage, dueDate: convertDateAsUTC(editingEmployee.dueDate as unknown as string), startDate: convertDateAsUTC(editingEmployee.startDate as unknown as string) });
                setErrors(validate({ ...editingEmployee, id: idEmployee, dueDate: convertDateAsUTC(editingEmployee.dueDate as unknown as string), startDate: convertDateAsUTC(editingEmployee.startDate as unknown as string) }));
            }
            else
                toast.error({ body: intl.get('employeeProject.toast.error.handleEmployeeEdit')});
        } else {
            setInput(DefaultEmployeeProjectInput);
        };
    };

    const handleEmployeeDelete = (idEmployee: number) => {
        console.log(idEmployee)
        confirmAlert({
            title: intl.get('delete.modal.title'),
            message: intl.get('confirm.modal.employeeProject.message'),
            buttons: [
                {
                    label: 'Cancel',
                    onClick: () => { }
                },
                {
                    label: 'Delete',
                    onClick: () => {
                        api.employeeProjectApi.apiVversionEmployeeProjectIdDelete(idEmployee, "1", {}).then((response) => {
                                toast.success({ body: intl.get('employeeProject.toast.success.handleEmployeeDelete')})
                                fetchEmployeesByProject(idProject);
                        }).catch((error) => {
                            handleAPIError(error, toast, errors);    
                            setErrors({ ...errors });            
                        });
                    },
                }
            ]
        });
    };

    const handleAddEmployeeProject = async (e) => {
        e.preventDefault();
        const errors = validate(input);
        if (JSON.stringify(errors) === JSON.stringify({})) {    
            const response = await api.employeeProjectApi.apiVversionEmployeeProjectPost("1", input, {}).then((response) => {
                toast.success({ body: intl.get('employeeProject.toast.success.handleAddEmployeeProject')});
                setInput(DefaultEmployeeProjectInput);
                fetchEmployeesByProject(idProject);
            }).catch((error) => {
                handleAPIError(error, toast, errors);    
                setErrors({ ...errors });            
            });
        }
    };

    const handleSaveEmployeeProject = async (e) => {
        
        e.preventDefault();
        console.log('arar',input);
        const errors = validate(input);
        if (JSON.stringify(errors) === JSON.stringify({})) {
            setLoading(true);
            const response = await api.employeeProjectApi.apiVversionEmployeeProjectPut("1", input, {}).then((response) => {
                toast.success({ body: intl.get('employeeProject.toast.success.handleSaveEmployeeProject')});
                setInput(DefaultEmployeeProjectInput);
                fetchEmployeesByProject(idProject);
            }).catch((error) => {
                handleAPIError(error, toast, errors);    
                setErrors({ ...errors });            
            }).then(() => { setLoading(false)});
        }
    };

    const handleCancelEdit = () => {
        setInput(DefaultEmployeeProjectInput);
    };

    const isEmployeeEditMode = (idEmployee: number) => {
        return isEditMode() && (input as UpdateEmployeeProjectCommand).id === idEmployee;
    };

    return (
        <>
            <div className="card-header">
                <h2 className="title">{intl.get('employeeProject.header')}</h2>
            </div>
            <div className="card-body">
                <PermissionsGate viewScopes={[SCOPES['projects.employees.edit'], SCOPES['projects.employees.add']]} editScopes={[SCOPES['projects.employees.edit'], SCOPES['projects.employees.add']]} viewRoles={[]} editRoles={[]} >
                    <EmployeeProjectForm
                        handleEmployeeChange={handleEmployeeChange}
                        employees={employees}
                        selectedEmployee={input.idEmployee}
                        handleInputChange={handleInputChange}
                        handleRoleChange={handleRoleChange}
                        selectedRole={input.idRole}
                        roles={roles}
                        handleStartDate={handleStartDate}
                        handleDueDate={handleDueDate}
                        handleAddEmployeeProject={handleAddEmployeeProject}
                        isEditMode={isEditMode}
                        handleSaveEmployeeProject={handleSaveEmployeeProject}
                        handleCancelEdit={handleCancelEdit}
                        input={input}
                        errors={errors}
                        disabled={disabled}
                        handleAllocationTypeChange={handleAllocationTypeChange}
                        selectedAllocationType={input.allocationType}
                        allocationTypes ={ALLOCATION_TYPES}
                        setHiddenRole={setHiddenRole}
                        setInput={setInput}
                    />
                </PermissionsGate>
                <div className='row mt-1'>
                    <div className='col-12'>
                        {
                            loading === true ?
                            <Spinner small={true} /> :
                            <>
                                <ProjectEmployeeList 
                                    handleEmployeeEdit={handleEmployeeEdit} 
                                    handleEmployeeDelete={handleEmployeeDelete} 
                                    isEmployeeEditMode={isEmployeeEditMode} 
                                    disabled={disabled} 
                                    input={input} 
                                    setInput={setInput} 
                                    inactiveEmployees={inactiveEmployees} 
                                    setInactiveEmployees={setInactiveEmployees} 
                                    employeesByProjectActivePaginated={employeesByProjectActivePaginated} 
                                    employeesByProjectPaginated={employeesByProjectPaginated}
                                />
                                <div className="pagination row w-100">
                                    <div className="col-10 col-xl-11 d-flex justify-content-center">
                                        <EmployeeProjectPagination 
                                            handlePageClick={handlePageClick} 
                                            limit={limit} 
                                            employeesByProject={employeesByProject} 
                                            nextPage={nextPage} 
                                            prevPage={prevPage} 
                                            input={input} 
                                            pageCount={pageCount} 
                                            setpageCount={setpageCount} 
                                            initialPage={initialPage} 
                                            finalPage={finalPage} 
                                            employeesByProjectActive={employeesByProjectActive} 
                                            inactiveEmployees={inactiveEmployees}
                                        />
                                    </div>
                                    <div className="col-2 col-xl-1 d-flex justify-content-end">
                                        <ItemsSelect
                                            input={input}
                                            setInput={setInput}
                                            limit={limit} 
                                            setLimit={setLimit} 
                                            storageKey="itemsPerPageEmployeeProyect"
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmployeeByProject;
